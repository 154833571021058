import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { htmlConverter } from '@utils/htmlConverter';

interface IChatMessage {
	subject?: string;
	body?: string;
	time?: Date;
	unread?: false;
	direction?: 'in' | 'out';
}

@customElement('chat-message')
export default class ChatMessage extends LitElement {
	@property({ type: Object }) message: IChatMessage = {};

	render() {
		const { body, time } = this.message;
		const convertedMsgBody = unsafeHTML(htmlConverter.render(body || ''));
		const convertTime = new Date(time || '');

		const convertedMsg = {
			...this.message,
			convertedMsgBody,
			time: convertTime,
		};

		return html`
			<yd-chat-message .message=${convertedMsg}></yd-chat-message>
		`;
	}
}
