// eslint-disable-next-line import/no-cycle
import { Model } from '@app/Model.js';
import { APIv2 } from '../../API.ts';
import { RocketChatSocketService } from './RocketChatSocketService.js';
import { RocketChatRouteService } from './RocketChatRouteService.js';

export const RocketChatService = {
	async init() {
		const rcProperties = this.getRocketChatProperties();

		const { token, chatUserId, chatUsername } = rcProperties.token
			? rcProperties
			: (await this.getAuth()) || {};

		const rooms = await this.getRooms();

		this.setRocketChatProperties({ token, rooms, chatUserId, chatUsername });
		await RocketChatSocketService.initAndAuthorize();
	},

	async getAuth() {
		const { data } = await APIv2.GET(`/chat/user/token`);
		return data;
	},

	async getRooms() {
		const { data } = await APIv2.GET(`/chat/user/rooms`);
		return data;
	},

	setRocketChatProperties(...props) {
		const existingProps = this.getRocketChatProperties() || {};

		Model.Storage.set('rocketchat', Object.assign(existingProps, ...props));
	},

	getRocketChatProperties(prop = '') {
		const storage = Model.Storage.get('rocketchat');
		return prop ? storage?.[prop] : storage || {};
	},

	checkDisplayAuthorization(platform, route) {
		const allowedRouteInfo = RocketChatRouteService[platform].allowed.find(
			(r) => r.route === route,
		);
		const disallowedRouteInfo = RocketChatRouteService[
			platform
		].disallowed.find((r) => r.route === route);
		if (!allowedRouteInfo && !disallowedRouteInfo) return false;
		if (allowedRouteInfo) return true;
		if (disallowedRouteInfo) return false;
		return false;
	},

	checkRules(platform, route) {
		const routeInfo = RocketChatRouteService[platform].allowed.find(
			(r) => r.route === route,
		);
		return routeInfo.rules;
	},
};
