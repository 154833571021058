import { html, LitElement, nothing } from 'lit';
import type { PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import style from './style.scss?inline';

type CriteriumRenderType = 'note' | 'point' | 'check' | 'level';
type Levels = Record<string, string>;
interface SourceElement extends HTMLElement {
	instance?: {
		sort: (order: string[], useAnimation: boolean) => void;
	};
	order: string[];
}
type Translations = {
	label?: {
		qualityCriteria?: Record<string, object>;
		qualityCriteria_levels?: Record<string, object>;
	};
};

@customElement('quality-criterium')
export default class QualityCriterium extends LitElement {
	@property({ type: String }) type: CriteriumRenderType = 'point';

	@property({ type: String }) label: string = '';

	// eslint-disable-next-line no-undefined
	@property({ type: Number }) value: number | undefined = undefined;

	@property({ type: Number }) weight: number = 1;

	@property({ type: Number }) index: number = 1;

	@property({ type: String }) mode: 'edit' | 'fill' | '' = '';

	@property({ type: Boolean }) isAI?: boolean = false;

	@property({ type: Boolean }) manualOverride: boolean = false;

	@property({ type: Boolean }) revertable: boolean = false;

	@property({ type: Object }) levels?: Levels = {};

	@property({ type: Boolean }) required: boolean = false;

	exposedInputs: Array<HTMLInputElement> = [];

	uid: string = `u${Math.random().toString(16).substr(2, 5)}`;

	levelLabels: Levels | null = null;

	from: SourceElement | null = null;

	translations: Translations | null = null;

	gradesMap = [6, 5, 4, 3, 2, 1];

	static styles = [style];

	toggleInputs(e: InputEvent) {
		const checkbox: HTMLInputElement | null =
			e.currentTarget as HTMLInputElement;
		const { checked } = checkbox;
		const targets: NodeListOf<HTMLInputElement> = this.querySelectorAll(
			`input[name^="form[${this.index}]"]`,
		);
		const shadowTargets: HTMLInputElement[] = Array.from(
			this.renderRoot.querySelectorAll(`input[name^="form[${this.index}]"]`),
		).filter(
			(t) => !(t as HTMLInputElement).name.includes('comment'),
		) as HTMLInputElement[];
		let additionalTarget = null;
		targets.forEach((t: HTMLInputElement) => {
			const target = t;
			target.disabled = checked;
			target.readOnly = checked;
		});
		shadowTargets.forEach((t: HTMLInputElement) => {
			const target = t;
			target.disabled = checked;
			target.readOnly = checked;
		});
		if (this.type === 'note') {
			additionalTarget = this.renderRoot.querySelector('sl-rating');
		}
		if (this.type === 'point') {
			additionalTarget = this.renderRoot.querySelector('yd-slider');
		}
		if (additionalTarget && checked)
			additionalTarget.setAttribute('disabled', '');
		if (additionalTarget && !checked)
			additionalTarget.removeAttribute('disabled');
		const event = new Event('change', { bubbles: true });
		this.dispatchEvent(event);
	}

	exposeInputs(revert = false) {
		// add/update inputs outside shadow dom to make them part of enclosing form
		const inputs: NodeListOf<HTMLInputElement> =
			this.renderRoot.querySelectorAll(
				'input[name], sl-rating:not([readonly])',
			);
		inputs.forEach((input: HTMLInputElement) => {
			const name = input.name ?? input.getAttribute('name');
			const value =
				input.value && input.nodeName.toLocaleLowerCase() === 'sl-rating'
					? this.gradesMap[Number(input.value) - 1].toString()
					: input.value;

			let formInput: HTMLInputElement | null = this.querySelector(
				`:scope > input[name="${name}"]`,
			);
			if (!formInput) {
				formInput = document.createElement('input');
				formInput.type = 'hidden';
				formInput.name = name;
				this.appendChild(formInput);
			}
			if (input.type === 'checkbox') {
				formInput.value = input.checked ? '1' : '0';
			} else if (input.type === 'radio') {
				if (input.checked) formInput.value = input.value;
			} else if (input.value) {
				formInput.value = value;
			}
			if (revert) formInput.value = '';
			formInput.disabled = (!formInput.value || revert) && this.required;

			if (!this.exposedInputs.includes(formInput))
				this.exposedInputs.push(formInput);
			const event = new Event('change', { bubbles: true });
			this.dispatchEvent(event);
		});
	}

	onChange(e: CustomEvent) {
		if (this.isAI && this.value) {
			let currentValue =
				Number((e.currentTarget as HTMLInputElement)?.value) || null;
			if (
				currentValue &&
				(e.currentTarget as HTMLInputElement).tagName.toLocaleLowerCase() ===
					'sl-rating'
			)
				currentValue = this.gradesMap[currentValue - 1];
			if (!currentValue && e.detail) currentValue = Number(e.detail.value);
			this.manualOverride = currentValue ? currentValue !== this.value : true;
		}
		this.exposeInputs();
	}

	removeFromList() {
		if (this.revertable && this.from) {
			this.mode = '';
			this.from.append(this);
			const sortableInstance = this.from.instance;
			if (sortableInstance && this.from.order)
				sortableInstance.sort(this.from.order, true);
		} else {
			this.remove();
		}
	}

	connectedCallback() {
		super.connectedCallback();
		this.translations =
			window.L10n?.translations?.[window.L10n?.language] || null;
	}

	protected updated(updatedProperties: PropertyValues<this>): void {
		const revert = updatedProperties.has('value') && !this.value;
		this.exposeInputs(revert);
	}

	render() {
		const levelLabels = this.translations?.label?.qualityCriteria_levels?.[
			this.label
		] as Levels;
		const criteriumLabel =
			this.translations?.label?.qualityCriteria?.[this.label];
		if (this.type === 'level' && levelLabels) {
			this.levelLabels = levelLabels;
		}

		const mapTypeToPlaceholder: Record<CriteriumRenderType, string> = {
			note: window.T.field.placeholder.qualitycheck_star_rating,
			check: window.T.field.placeholder.qualitycheck_yes_no_choice,
			point: window.T.field.placeholder.qualitycheck_point_rating,
			level: window.T.field.label.qualitycheck_criterion,
		};

		return html`
			<yd-card>
				${when(
					this.mode === 'edit' && !this.isAI,
					() => html`
						<input
							class="label"
							type="text"
							name="form[${this.index}][label]"
							value=${this.label}
							@change=${(e: Event) => {
								this.label = (e.currentTarget as HTMLInputElement)?.value;
							}}
							placeholder=${mapTypeToPlaceholder[this.type]}
							required
						/>
					`,
					() => html`
						<label>
							${criteriumLabel || this.label}
							${when(
								this.isAI,
								() => html`
									<yd-tooltip
										class="ai-badge ${classMap({
											disabled: this.manualOverride || !this.value,
										})}"
										.tip=${this.manualOverride
											? window.T.hint.qualitycheck_ai_overriden
											: ''}
									></yd-tooltip>
								`,
							)}
						</label>
					`,
				)}
				${when(
					this.mode === 'fill' && this.isAI && typeof this.value === 'number',
					() => html`
						<div class="ai-hint">
							${window.T.notice.qualitycheck_criteria_has_ai}
						</div>
					`,
				)}
				${when(
					this.mode === 'edit',
					() => html`
						<yd-circle-icon indicationType="danger">
							<iconify-icon
								icon="mdi-delete-outline"
								width="16"
								class="iconify"
								@click=${this.removeFromList}
							></iconify-icon>
						</yd-circle-icon>
					`,
				)}
				${when(
					this.type === 'note',
					() => html`
						<small>${window.T.field.label.qualitycheck_stars}</small>
						<sl-rating
							name=${this.mode === 'fill'
								? `form[${this.index}][rating]`
								: nothing}
							value=${typeof this.value !== 'undefined'
								? this.gradesMap[this.value - 1]
								: nothing}
							@sl-change=${this.onChange}
							max="6"
							?readonly=${this.mode !== 'fill'}
						></sl-rating>
					`,
				)}
				${when(
					this.type === 'point',
					() => html`
						<small>${window.T.unit.points} (0-100)</small>
						<yd-slider
							.thumb=${{
								name: this.mode === 'fill' ? `form[${this.index}][rating]` : '',
								value: typeof this.value === 'number' ? this.value : 100,
							}}
							@change=${this.onChange}
							?disabled=${this.mode !== 'fill'}
						>
							<input
								name="form[${this.index}][rating]"
								type="hidden"
								value=${typeof this.value === 'number' ? this.value : 100}
							/>
						</yd-slider>
					`,
				)}
				${when(
					this.type === 'check',
					() => html`
						<div class="check">
							<input
								type="radio"
								name="${this.mode === 'fill'
									? `form[${this.index}][rating]`
									: nothing}"
								value="1"
								id="${this.uid}y"
								@change=${this.onChange}
								?checked=${this.value === 1}
								?disabled=${this.mode !== 'fill'}
							/>
							<label for="${this.uid}y">${window.T.term.yes}</label>
						</div>
						<div class="check">
							<input
								type="radio"
								name="${this.mode === 'fill'
									? `form[${this.index}][rating]`
									: nothing}"
								value="0"
								id="${this.uid}n"
								@change=${this.onChange}
								?checked=${this.value === 0}
								?disabled=${this.mode !== 'fill'}
							/>
							<label for="${this.uid}n">${window.T.term.no}</label>
						</div>
					`,
				)}
				${when(
					this.type === 'level' && this.levels,
					() => html`
						${Object.entries(this.levels || {}).map(
							([value, label]) => html`
								<div class="check">
									<input
										type="radio"
										name="${this.mode === 'fill'
											? `form[${this.index}][rating]`
											: nothing}"
										value=${value}
										id="${this.uid}_${value}"
										@change=${this.onChange}
										?checked=${this.value === Number(value)}
										?disabled=${this.mode !== 'fill'}
									/>
									<label for="${this.uid}_${value}">
										${this.levelLabels?.[value] || label}
									</label>
								</div>
							`,
						)}
					`,
				)}
				${when(
					this.mode === 'edit',
					() => html`
						<div class="weight">
							<small>${window.T.field.label.qualitycheck_weighting}:</small>
							<input
								type="number"
								min="1"
								max="100"
								name="form[${this.index}][weight]"
								value=${this.weight}
								@change=${(e: Event) => {
									this.weight =
										Number((e.currentTarget as HTMLInputElement)?.value) || 1;
								}}
							/>
						</div>
						<input
							type="hidden"
							name="form[${this.index}][type]"
							value=${this.type}
						/>
					`,
				)}
				${when(
					this.mode === 'fill',
					() => html`
						${when(
							this.weight > 1,
							() => html`
								<yd-tooltip
									class="fst-italic text-nowrap"
									tip="${window.T.term.weighting}"
								>
									<iconify-icon
										icon="mdi-weight"
										class="iconify"
									></iconify-icon>
									x${this.weight}
								</yd-tooltip>
							`,
						)}
						<div class="check norating">
							<input
								type="checkbox"
								id="norating-${this.index}"
								@change=${this.toggleInputs}
							/>
							<label for="norating-${this.index}">
								<small>${window.T.cta.notrated}</small>
							</label>
						</div>
						<input
							type="text"
							placeholder="${window.T.field.placeholder
								.qualitycheck_criterion_comment}"
							name="form[${this.index}][comment]"
							@change=${this.onChange}
						/>
					`,
				)}
			</yd-card>
		`;
	}
}
