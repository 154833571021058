import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import style from './style.scss?inline';

import { type RichTextField } from '@prismicio/client';
import { prismicRichText } from '@utils/index.js';
import { when } from 'lit/directives/when.js';

@customElement('prismic-richtext')
export default class PrismicRichtext extends LitElement {
	@property({ type: Array }) field: RichTextField = [];

	static styles = [style];

	render() {
		return when(
			this.field,
			() => html`
				${prismicRichText(this.field)}
			`,
		);
	}
}
