import createClient from 'openapi-fetch';
import type { paths } from '@app/@types/API';
import { deepNestedObjectToQueryString } from '@utils';

const appToApiControllerMap = {
	manage: 'manage',
	talent: 'provider',
	onboarding: 'onboard',
	backoffice: 'admin',
};
const currentApp = import.meta.env.VITE_APP;
const baseUrls = {
	v1: `${import.meta.env.VITE_API_HOST}${appToApiControllerMap[currentApp]}/`,
	v2: `${import.meta.env.VITE_API_HOST}v2/`,
};
const baseUrl = baseUrls.v1;

export { baseUrl as apiBaseURL };
export const APIv2 = createClient<paths>({
	baseUrl: baseUrls.v2,
	credentials: 'include',
});

export const API = createClient<paths>({
	baseUrl,
	credentials: 'include',
	bodySerializer: deepNestedObjectToQueryString,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
	},
});

export const UploadAPI = createClient<paths>({
	baseUrl,
	credentials: 'include',
});
