import { globals } from '@globals';
import { User } from '@app/User';
import { serializationOfIterator } from '@utils';
import { Dialog } from '../../app/DialogSystem.js';
import { Log } from '../../app/Log.js';
import Localisation from '../../l10n/index.js';
import { Router } from '../../router/index.js';

export const initDEPRECATED = async (appElement: HTMLElement) => {
	// should/could be integrated in a <yd-form> component one day
	HTMLFormElement.prototype.serializeObject = function serializeObject() {
		const formEntries = new FormData(this).entries();
		return serializationOfIterator(formEntries);
	};

	// backwards compatibility, should be imported where needed instead
	const L10n = new Localisation();
	await L10n.setLanguage(User.preferredLanguage);

	if (globals.gui === 'talent' && !User.device.hasTouchScreen) {
		const { TypingDNA } = await import('../../modules/dna.js');
		window.Tdna = new TypingDNA();
	}

	Object.assign(window, {
		T: L10n.txt,
		prismicContent: L10n.prismicContent,
		appElement,
		Dialog,
		L10n,
		Log,
		Router,
	});
};

export const onreadyDEPRECATED = (appElement: HTMLElement) => {
	const { shadowRoot } = appElement;
	shadowRoot?.addEventListener('change', (e) => {
		const target = e.target as HTMLInputElement;

		if (typeof target.name === 'string' && target.name?.startsWith('tab')) {
			shadowRoot.querySelectorAll('[for^="tab"]').forEach((forTab) => {
				const activateTab = shadowRoot.querySelector(
					`#${forTab.getAttribute('for')}`,
				) as HTMLInputElement;
				if (activateTab && 'checked' in activateTab)
					forTab.classList.toggle('active', activateTab.checked);
			});
		}

		if (target.hasAttribute('data-for')) {
			const dataFor = target.getAttribute('data-for') as string;
			const checkboxes = shadowRoot.querySelectorAll(
				dataFor,
			) as NodeListOf<HTMLInputElement>;
			checkboxes.forEach((checkbox) => {
				checkbox.checked = target.checked;
				checkbox.dispatchEvent(new Event('change', { bubbles: true }));
			});
		}

		if (target.nodeName === 'SELECT') {
			if (target.classList.contains('href')) {
				Router.navigate(target.value);
			}
		}
	});

	shadowRoot?.addEventListener('click', (e) => {
		const target = e.target as HTMLElement;

		if (target.hasAttribute('disabled')) {
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();
		}
	});
};
