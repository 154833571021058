import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { when } from 'lit/directives/when.js';
import style from './style.scss?inline';

interface ThumbUser {
	name?: string;
	employment?: 'empl' | 'sole';
	xpLevel?: number;
	imgSmall?: string;
	ymdItself?: boolean;
	type?: 'sp' | 'cu';
	poweredBy?: 'ymmd';
	country?: keyof StaticCountries;
	pseudonym?: string;
	id?: string;
}

@customElement('user-thumb')
export default class UserThumb extends LitElement {
	@property({ type: String }) size: string | 'full' = '40';

	@property({ type: Object }) user: ThumbUser = {};

	@property({ type: Boolean }) hasCountry: boolean = false;

	@property({ type: Boolean }) showLink: boolean = false;

	@property({ type: Boolean }) forCarousel: boolean = false;

	@property({ type: Boolean }) newsIndicator: boolean = false;

	@property({ type: Boolean }) showXps: boolean = true;

	static styles = [style];

	render() {
		const { countries } = window.appElement?.statics || { countries: {} };
		const { country, poweredBy, ymdItself, imgSmall, name, type, xpLevel } =
			this.user;

		const levelBadge = `metal-${xpLevel ? Math.ceil(xpLevel / 10) : 0}`;
		const displayLvl = xpLevel ? xpLevel % 10 || 10 : 0;

		if (imgSmall && !ymdItself) {
			this.style.setProperty('--user-img', `url(${imgSmall})`);
		} else {
			this.style.setProperty('--user-img', '');
		}

		if (this.size !== 'full') {
			this.style.setProperty('--thumb-size', `${this.size}px`);
		}

		const classes = [
			'thumb',
			...(this.forCarousel ? ['carousel-thumb'] : []),
			...(this.size === 'full' ? ['full'] : []),
			...(ymdItself ? ['ymmd'] : []),
		];

		return html`
			<div
				class="${classes.join(' ')}"
				title="${ifDefined(name)}"
			>
				${when(
					this.newsIndicator,
					() => html`
						<div class="news-indicator"></div>
					`,
				)}
				${when(
					xpLevel && this.showXps,
					() => html`
						<div
							class="crown ${levelBadge}"
							data-lvl="${displayLvl}"
						></div>
					`,
				)}
				${when(
					!imgSmall && !ymdItself,
					() => html`
						<iconify-icon
							icon=${type === 'cu' ? 'mdi-office-building' : 'mdi-account'}
							class="no-profile-img"
						></iconify-icon>
					`,
				)}
				${when(
					poweredBy === 'ymmd',
					() => html`
						<yd-tooltip
							class="managedby"
							.tip="${window.T.hint.whitelabel_poweredby}"
						>
							<iconify-icon
								icon="ymmd:logo-visual"
								width="unset"
							></iconify-icon>
						</yd-tooltip>
					`,
				)}
				${when(
					this.hasCountry && country,
					() => html`
						<yd-tooltip
							.tip=${countries[country]}
							class="flag"
						>
							<iconify-icon
								icon="cif-${country.toLowerCase()}"
								width="20"
							></iconify-icon>
						</yd-tooltip>
					`,
				)}
			</div>

			${when(
				this.showLink,
				() => html`
					<div class="profile-link">
						<a
							href="profile"
							target="_blank"
						>
							${this.user.name}
							<iconify-icon
								icon="ic:baseline-open-in-new"
								width="16"
							></iconify-icon>
						</a>
					</div>
				`,
			)}
		`;
	}
}
