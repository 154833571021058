import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import styles from './style.scss?inline';

@customElement('yd-content-list')
export default class YdContentList extends LitElement {
	@property({ type: Boolean }) scrollable = false;

	static styles = [styles];

	render() {
		return html`
			<div class="main ${this.scrollbar ? 'scrollbar' : ''}">
				<slot></slot>
			</div>
		`;
	}
}
