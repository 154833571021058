
			import { User } from '@app/User.ts';
			import { globals } from '@globals';

			const initSentry = () => {
				Sentry.onLoad(() => {
					Sentry.init({
						release: '4.20.0',
						environment: window.location.hostname,
						enabled: globals.sentryOn,
						allowUrls:
							/https:\/\/((talent|manage|onboarding)(\.|-qa\.))?yoummday\.com/,
						maxBreadcrumbs: 30,
						initialScope(scope) {
							scope.setExtra(
								'Unsupported Browser Functions',
								Object.keys(window.supportedBrowserFunctions)
									.filter(
										(feature) => !window.supportedBrowserFunctions[feature],
									)
									.join(', ') || '-',
							);

							if (User.user) {
								const { onboardTS, type, features } = User.user;
								scope.setUser({ onboarded: !!onboardTS, type, features });
							}

							if (window.Router) {
								const { route: { name = '?' } = {} } =
									window.Router.getCurrentLocation() || {};
								scope.setTag('View', name);
							}
							return scope;
						},
					});
				});
			};
			if (typeof Sentry !== 'undefined') initSentry();
		