import { Model } from '@app/Model.js';
import { html, LitElement } from 'lit';
import type { PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import style from './style.scss?inline';

@customElement('mail-indicator')
export default class MailIndicator extends LitElement {
	@property({ type: String }) companyuser: string = '';

	@property({ type: Number }) counter: number = 0;

	limit: number = 100;

	private limitExceededSymbol: '' | '+' = '';

	static styles = [style];

	async fetchUnread() {
		if (!this.companyuser) return;
		const { messages = [] } = await Model.data.getconversationlist({
			limit: this.limit,
			unreadonly: 1,
			companyuser: this.companyuser,
		});

		if (!this.isConnected) {
			return;
		}
		if (messages.length !== -1) {
			if (messages.length === this.limit) this.limitExceededSymbol = '+';
			this.counter = Math.min(messages.length, this.limit);
		}
	}

	updated(changedProperties: PropertyValues<this>) {
		if (changedProperties.has('companyuser')) {
			this.fetchUnread();
		}
	}

	render() {
		return html`
			<iconify-icon
				icon="mdi-email"
				class="iconify"
				width="24"
			></iconify-icon>
			<span>${this.counter || ''}${this.limitExceededSymbol}</span>
		`;
	}
}
