import JsSIP from 'jssip';
import { DTMF_TRANSPORT } from 'jssip/lib/Constants.js';
import { ringtone, notification } from '@utils';
import { globals } from '@globals';
import type { PhoneInstance, EventData } from './types';

let workerPort: MessagePort;

const phoneInstance: PhoneInstance = {
	guis: [],
	sessionId: null,
	user: '',
	password: '',
	ua: null,
	incomingAudio: new Audio(),
	autoAnswer: false,
	answeroptions: {
		pcConfig: {
			iceServers: [
				{
					urls: [
						'stun:stun.l.google.com:19302',
						'stun:stun1.l.google.com:19302',
					],
				},
			],
		},
		mediaConstraints: {
			audio: true,
			video: false,
		},
		rtcAnswerConstraints: {
			offerToReceiveAudio: true,
			offerToReceiveVideo: false,
			// voiceActivityDetection: false,
		},
		/*
		 * rtcOfferConstraints: {
		 * 	voiceActivityDetection: false,
		 *},
		 */
		sessionTimersExpires: 180,
	},
	call: null,
	_state: 'offline',
	get state() {
		return this._state;
	},
	set state(state) {
		const previousState = this._state;
		switch (state) {
			case 'online':
				window.removeEventListener('beforeunload', this.warnBeforeunload);
				if (previousState === 'talking' || previousState === 'ringing') {
					// switched from running call to no running call
					this.call = null;
					phoneInstance.ua?.register();
					this.guis[0].toggleAnswerDialog();
				}
				ringtone.pause();
				break;
			case 'ringing': {
				try {
					const playRingtone = ringtone.play();
					playRingtone.catch((error) => {
						window.Log?.log(error);
					});
				} catch (error) {
					window.Log?.log(error);
				}
				if (!this.autoAnswer) this.guis[0].toggleAnswerDialog(true);
				break;
			}
			case 'talking':
				ringtone.pause();
				this.incomingAudio.play();
				break;
			default:
				break;
		}
		this._state = state;
		this.guis.forEach((gui) => {
			gui.requestUpdate();
		});
	},
	toggleAutoAnswer() {
		this.autoAnswer = !this.autoAnswer;
		this.guis.forEach((gui) => {
			gui.requestUpdate();
		});
	},
	warnBeforeunload(e) {
		e.preventDefault();
		e.returnValue = false; // chrome wants this
	},
	start() {
		if (!this.ua) {
			const socket = new JsSIP.WebSocketInterface(globals.phoneSocket);
			this.ua = new JsSIP.UA({
				password: this.password,
				sockets: [socket],
				uri: `sip:${this.user}@sip.yoummday.com`,
			});

			this.ua
				.on('unregistered', () => {
					this.state = this.state === 'foreign' ? this.state : 'offline';
				})
				.on('registered', ({ response }) => {
					// eslint-disable-next-line
					// @ts-ignore
					// eslint-disable-next-line camelcase
					const { call_id } = response;
					this.state = 'online';
					// eslint-disable-next-line camelcase
					this.sessionId = call_id;
					workerPort.postMessage({ sessionId: phoneInstance.sessionId });
				})
				.on('registrationFailed', () => {
					this.state = 'offline';
				})
				.on('registrationExpiring', () => {
					this.ua?.register();
				})
				// eslint-disable-next-line
				// @ts-ignore
				// eslint-disable-next-line
				.on('newRTCSession', async ({ session }) => {
					this.call = session;
					this.state = 'ringing';
					window.addEventListener('beforeunload', this.warnBeforeunload);
					session
						.on('ended', (data: EventData['FailedOrEnded']) => {
							window.Log.log('onended', data);
							this.state = 'online';
						})
						// eslint-disable-next-line require-await
						.on('failed', async (data: EventData['FailedOrEnded']) => {
							window.Log.log('onfailed', data);
							this.state = 'online';
						})
						.on('peerconnection', (data: EventData['PeerConnection']) => {
							window.Log.log('onpeerconnection', data);
							data.peerconnection.addEventListener('track', (e) => {
								window.Log.log('ontrack', e);
								const [stream] = e.streams;
								this.incomingAudio.srcObject = stream;
								this.state = 'talking';
							});
						})
						.on('icecandidate', (data: EventData['IceCandidate']) => {
							if (data.candidate.candidate.indexOf('srflx') !== -1)
								data.ready();
						})
						.on('sdp', (data: EventData['SdpEvent']) => {
							if (data.originator === 'local' && data.sdp) {
								// eslint-disable-next-line no-param-reassign
								data.sdp = data.sdp.replace(
									/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\.local/giu,
									'192.168.1.2',
								);
							}
							window.Log.log('onsdp', data);
						});

					if (document.visibilityState === 'hidden') {
						const banner = notification(window.T.message.event.call_new);
						document.addEventListener('visibilitychange', () => {
							if (document.visibilityState === 'visible' && banner)
								banner.close();
						});
					}

					try {
						this.answeroptions.mediaStream =
							await navigator.mediaDevices.getUserMedia({ audio: true });
					} catch (err) {
						window.Log?.error?.(err as Error);
						session.terminate();
						window.Dialog.error(window.T.alert.error.allow_microphone);
						return;
					}

					if (this.autoAnswer)
						setTimeout(() => {
							this.answer();
						}, 2000);
				});
		}
		if (!this.ua.isConnected()) this.ua.start();
	},
	answer(e) {
		if (e) e.preventDefault();
		if (this.call) this.call.answer(this.answeroptions);
	},
	terminate(e) {
		if (e) e.preventDefault();
		if (this.call) {
			this.call.terminate();
		}
		if (this.answeroptions.mediaStream) {
			this.answeroptions.mediaStream
				.getTracks()
				.forEach((track) => track.stop());
		}
	},
	sendDTMF(key) {
		this.call?.sendDTMF(key, {
			transportType: DTMF_TRANSPORT.RFC2833,
		});
	},
};

try {
	const { port } = new SharedWorker(
		new URL('./phoneWorker', import.meta.url).href,
	);
	workerPort = port;
	workerPort.onmessage = ({ data: sessionId = '' }) => {
		if (sessionId === phoneInstance.sessionId || !phoneInstance.ua) return;

		if (
			sessionId &&
			phoneInstance.ua.isRegistered() &&
			(phoneInstance.state !== 'talking' || phoneInstance.state !== 'ringing')
		) {
			phoneInstance.state = 'foreign';
			phoneInstance.ua.unregister();
		}

		if (!sessionId && !phoneInstance.ua.isRegistered()) {
			phoneInstance.ua.register();
		}
	};

	const unregisterOnUnload = () => {
		if (phoneInstance.ua && phoneInstance.ua.isRegistered()) {
			phoneInstance.ua.unregister();
			workerPort.postMessage('');
		}
		workerPort.postMessage({ cmd: 'closed' });
	};
	window.addEventListener('beforeunload', unregisterOnUnload);
} catch (err) {
	window.Log?.error?.(err as Error);
	// eslint-disable-next-line
	// @ts-ignore
	workerPort = {
		postMessage: () => {},
	};
}

const webphoneIsActive = () => !!phoneInstance.ua?.isConnected();

export { phoneInstance, webphoneIsActive };
