import { globals } from '@globals';
import { Log } from '../../app/Log.js';

export let newestVersion = globals.appVersion;

export const isUpdateAvailable = (): boolean =>
	newestVersion !== globals.appVersion;

if (globals.isProduction) {
	window.setInterval(
		async () => {
			try {
				const { version = '' } = await (
					await fetch(
						`${
							import.meta.env.BASE_URL !== '/' ? import.meta.env.BASE_URL : ''
						}/version.json`,
					)
				).json();
				newestVersion = version;
			} catch (err) {
				Log.error(err);
			}
		},
		5 * 60 * 1000,
	);
}
