import { type ECharts as EChartsInstance } from 'echarts';

export interface ChartReadyPayload {
	chart: EChartsInstance;
}

export const LitEchartsOptions = (options) => ({
	tooltip: {
		trigger: 'item',
	},
	color: [
		'#7277DA',
		'#FDB99D',
		'#8FCEFB',
		'#CB70E2',
		'#D2D2D2',
		'#F4DE8E',
		'#FB8078',
		'#A798FF',
		'#8CE1DC',
		'#F5A8F6',
		'#6ECA71',
		'#62B3ED',
		'#96E09E',
		'#F4B65B',
		'#B1B1AA',
		'#CDDDA0',
		'#E97194',
		'#56CACA',
		'#F996A8',
		'#BBC261',
	],
	...options,
	grid: {
		left: options.grid?.left || (options.yAxis?.name ? 90 : 65),
		right: options.grid?.right || 20,
		top: options.grid?.top || 60,
	},
	textStyle: {
		color: '#3D424A',
		fontFamily: 'Helvetica, Arial, sans-serif',
	},
	title: {
		text: options?.title?.text || '',
		subtext: '',
		left: 'center',
		top: 'top',
		textStyle: {
			fontWeight: 'bold',
			fontSize: 18,
		},
	},
	toolbox: {
		show: options.toolbox?.show || false,
		itemSize: 18,
		iconStyle: {
			color: '#696e75',
			borderColor: '#696e75',
		},
		emphasis: {
			iconStyle: {
				color: '#7d82ee',
				borderColor: '#7d82ee',
			},
		},
		feature: {
			...options.toolbox?.feature,
			dataZoom: {
				yAxisIndex: 'none',
				icon: null,
				title: {
					back: window.T.cta.zoomOut,
				},
			},
		},
	},
});

export const LitEchartsExceptPieChartOptions = (options) => ({
	legend: {
		top: 'bottom',
		type: 'scroll',
		textStyle: {
			fontSize: 12,
		},
		icon: 'circle',
	},
	xAxis: {
		type: options.xAxis?.type || 'time',
		axisLine: {
			show: true,
			lineStyle: {
				color: '#d4d9e2',
			},
		},
		...(options.xAxis?.axisLabel
			? { axisLabel: options.xAxis?.axisLabel }
			: null),
	},
	yAxis: {
		type: 'value',
		name: options.yAxis?.name,
		nameLocation: 'middle',
		nameGap: '65',
		axisLine: {
			show: true,
			lineStyle: {
				color: '#d4d9e2',
			},
		},
		splitLine: {
			lineStyle: {
				color: '#d4d9e2',
			},
		},
		...(options.yAxis?.axisLabel
			? { axisLabel: options.yAxis?.axisLabel }
			: null),
	},
});
