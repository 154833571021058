import { LitElement, html, css } from 'lit';
import type { TemplateResult, PropertyValues, CSSResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import {
	LitEchartsOptions,
	LitEchartsExceptPieChartOptions,
} from './lit-echarts.def';
import * as echarts from 'echarts';
import type { EChartsOption } from 'echarts';
import langDE from 'echarts/lib/i18n/langDE.js';

@customElement('lit-echarts')
export default class LitECharts extends LitElement {
	static styles: CSSResult = css`
		:host {
			display: block;
			height: 100%;
		}
		.chart {
			width: 100%;
			height: 100%;
		}
	`;

	@query('.chart') chart!: HTMLDivElement;

	/** Configuration item and data */
	@property({ type: Object }) option!: EChartsOption;

	@property({ type: String }) type: string = '';

	/** Optional chart configurations **/
	@property({ type: Object }) opts = {};

	private resizeObserver!: ResizeObserver;

	private showNoData() {
		const echartsInstance = this.getEchartsInstance();
		if (echartsInstance) {
			echartsInstance.setOption(
				{
					title: {
						subtext: window.T.message.info.emptydata,
						left: 'center',
						top: 'center',
						subtextStyle: {
							fontSize: 20,
						},
					},
				},
				{ notMerge: true },
			);
		}

		return echartsInstance;
	}

	public getEchartsInstance() {
		return (
			echarts.getInstanceByDom(this.chart) ||
			echarts.init(this.chart, null, {
				...this.opts,
				locale: window.L10n.language,
			})
		);
	}

	private renderNewEchart(): void {
		// 1. new echarts instance
		this.updateEChartsOption();
		// 2. observe resize
		if (this.chart) {
			this.resizeObserver.observe(this.chart);
		}
	}

	private updateEChartsOption() {
		// 1. get or initial the echarts object
		const echartInstance = this.getEchartsInstance();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const series: any = this.option?.series || {};

		// 2. show no Data if there are no data
		if (
			!series?.length ||
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			!series?.filter((serie: any) => serie.data?.length)?.length
		) {
			return this.showNoData();
		}

		// 3. set the echarts option
		echartInstance.setOption(
			{
				...LitEchartsOptions(this.option),
				...(this.type !== 'pieChart'
					? { ...LitEchartsExceptPieChartOptions(this.option) }
					: null),
			},
			{
				replaceMerge: ['xAxis', 'yAxis', 'series', 'grid'],
			},
		);

		return echartInstance;
	}

	private _onResize(entries: ResizeObserverEntry[]): void {
		for (const entry of entries) {
			if (entry.contentRect) {
				this.getEchartsInstance().resize();
			}
		}
	}

	private dispose(): void {
		if (!this.chart) return;

		// unobserve resize observer
		if (this.resizeObserver) {
			this.resizeObserver.unobserve(this.chart);
		}

		// dispose echarts instance
		echarts.dispose(this.chart);
	}

	firstUpdated(): void {
		if (!echarts) return;
		if (window.L10n?.language === 'de') echarts.registerLocale('de', langDE); // in case we need to translate echarts texts

		// Create the ResizeObserver and set it to observe the chart element
		this.resizeObserver = new ResizeObserver(this._onResize.bind(this));

		this.renderNewEchart();

		this.getEchartsInstance()?.dispatchAction({
			type: 'takeGlobalCursor',
			key: 'dataZoomSelect',
			// Activate or inactivate.
			dataZoomSelectActive: true,
		});

		this.getEchartsInstance()?.on('datazoom', (e) => {
			const echartInstance = this.getEchartsInstance();
			// eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias
			const fnt = this;
			if (e.batch?.length && e.batch[0]?.start === 0) {
				this.option = {
					...this.option,
					toolbox: {
						show: false,
					},
				};
				return;
			}

			this.option = {
				...this.option,
				toolbox: {
					show: true,
					feature: {
						myCustomZoomButton: {
							show: true,
							title: window.T.cta.zoomOut,
							icon: 'path://M312.5-567q-3.75 0-6.12-2.32-2.38-2.33-2.38-6 0-3.68 2.38-6.43 2.37-2.75 6.12-2.75H461q3.75 0 6.38 2.57 2.62 2.58 2.62 6.25 0 3.68-2.62 6.18-2.63 2.5-6.38 2.5H312.5Zm74.69 188q-82.39 0-139.54-57.29-57.15-57.29-57.15-139 0-81.71 57.04-139.21Q304.58-772 386.79-772q81.71 0 139.21 57.4 57.5 57.4 57.5 139.29 0 36.73-13.5 72.52t-35 62.29l229.12 228.64q3.38 3.36 3.88 5.61.5 2.25-4 6.75-5 4.5-7 4.5t-6.38-4.37L521.66-427.5q-29.66 24-65.45 36.25Q420.42-379 387.19-379Zm-.19-17.5q75.5 0 127.25-51.5T566-575.5q0-76-51.75-127.5T387-754.5q-76 0-127.5 51.5T208-575.5q0 76 51.5 127.5T387-396.5Z', // tried different options here
							onclick() {
								echartInstance.dispatchAction({
									type: 'dataZoom',
									start: 0,
									end: 100,
								});
								fnt.option = {
									...fnt.option,
									toolbox: {
										show: true,
										feature: {
											myCustomZoomButton: {
												show: false,
											},
										},
									},
								};
							},
						},
					},
				},
			};
		});
	}

	updated(properties: PropertyValues): void {
		super.updated(properties);

		if (properties.has('option')) {
			this.renderNewEchart();
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.dispose();
	}

	// eslint-disable-next-line class-methods-use-this
	render(): TemplateResult {
		return html`
			<div class="chart"></div>
		`;
	}
}
