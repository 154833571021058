import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import styles from './style.scss?inline';

@customElement('yd-carousel-item')
export default class YdCarouselItem extends LitElement {
	@property({ type: Boolean }) currentItem = false;

	@property({ type: Boolean }) active = false;

	static styles = [styles];

	render() {
		return html`
			<div
				@click=${this.handleClick}
				class="item-carousel ${classMap({
					'current-item': this.currentItem,
					'active-item': this.active,
				})}"
			>
				<slot></slot>
			</div>
		`;
	}

	handleClick() {
		this.dispatchEvent(
			new CustomEvent('item-click', {
				bubbles: true,
				composed: true,
				detail: { item: this, active: this.active },
			}),
		);
	}
}
