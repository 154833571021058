import moment from 'moment-timezone';
import { Model } from '@app/Model.js';
import { socket } from './Socket.js';

export type DeviceType = 'mobile' | 'desktop';

export interface User {
	hasSession: boolean;
	browserNotifications: NotificationPermission | null;
	systemzone: string;
	userObjectReceived: (user: anyAPIUser) => Promise<void>;
	device: {
		type: DeviceType;
		hasTouchScreen: boolean;
	};
	user: someAPIUser;
	logout: () => Promise<void>;
	preferredLanguage: string;
	emailVerified: boolean;
	can: (feature: string) => boolean;
	ymmdTeam: boolean;
	ymdMail: string;
}

export class BaseUser implements User {
	private _hasSession = false;

	browserNotifications =
		'Notification' in window ? Notification.permission : null;

	systemzone: User['systemzone'] =
		Intl.DateTimeFormat().resolvedOptions().timeZone;

	async userObjectReceived(user: anyAPIUser) {
		this.user = { ...user };
		Model.Storage.local.set('authUser', user);
		const { lang, timezone, features = [] } = user;
		if (lang) await window.L10n.setLanguage(lang);
		if (timezone) moment.tz.setDefault(timezone);
		if (features.length) Model.Storage.local.set('features', features);
	}

	async logout() {
		await Model.data.logout();
		this.hasSession = false;
	}

	get user(): anyAPIUser {
		return Model.Storage.local.get('authUser') || {};
	}

	set user(updatedUser: anyAPIUser) {
		Model.Storage.local.set('authUser', {
			...this.user,
			...updatedUser,
		});
	}

	get hasSession() {
		return this._hasSession;
	}

	set hasSession(bool) {
		this._hasSession = bool;
		if (!this._hasSession) {
			window.Router.navigate('/login', {
				updateBrowserURL: false,
			});

			socket.kill();
			Model.cleanup();
		}
	}

	get preferredLanguage() {
		return this.user.lang || window.navigator.language || 'en';
	}

	get emailVerified() {
		return !!this.user.emailverified;
	}

	can(feature = '') {
		const { features = [] } = this.user;
		return features.includes(feature);
	}

	get ymmdTeam() {
		return (
			!!this.user.email &&
			/@(external\.|icon\.)?yoummday\.com$/u.test(this.user.email)
		);
	}

	get ymdMail() {
		return this.user?.ymdmail;
	}

	get device() {
		const type: DeviceType = window.innerWidth <= 768 ? 'mobile' : 'desktop';
		const hasTouchScreen =
			'ontouchstart' in document.documentElement &&
			navigator.maxTouchPoints > 1;
		return { type, hasTouchScreen };
	}
}
