import { Model } from '@app/Model.js';
import type { User } from '../User';
import { BaseUser } from '../User';
import { matomoTracker } from '../matomoTracker';

interface TalentUserInterface extends User {
	isOnboarded: boolean;
	isEmployed: boolean;
	hasBillingAddress: boolean;
	hasWorkAddress: boolean;
	hasValidTaxnumber: boolean;
	hasValidBankaccount: boolean;
	hasAcceptedBillingAddress: boolean;
	hasTaxInfo: boolean;
	hasPassport: boolean;
	hasScriptedEvaluation: boolean;
	hasNonScriptedEvaluation: boolean;
	hasListeningEvaluation: boolean;
	hasSignedPrivacyDocs: boolean;
	spokenLangs: string[][];
	speedtestResults: number[];
	speedtestValuation: number[];
	obStep: 'countdown' | 'documents' | '';
	user: APItalent;
	isTaxTransparencyCompliant: boolean;
}

class TalentUser extends BaseUser implements TalentUserInterface {
	userObjectReceived(user: anyAPIUser) {
		super.userObjectReceived(user);
		const { newlyOnboarded = 0 } = user;
		if (newlyOnboarded) {
			matomoTracker.conversionTrack('onboarding-complete');
			Model.data.deleteNewlyObFlag();
		}
	}

	get isOnboarded() {
		return !!(
			this.user.emailverified &&
			(this.user.onboardTS || this.user.employment === 'empl')
		);
	}

	get spokenLangs() {
		return this.user.spokenLangs
			? // todo remove filter() once BE removed dialects
				this.user.spokenLangs
					.sort()
					.map((l: string) => l.split(':'))
					.filter((l: []) => !/\d/u.test(l[0]))
			: [];
	}

	get speedtestResults() {
		return this.user.speedtest && this.user.speedtest.includes(':')
			? this.user.speedtest.split(':').map((n: string) => parseInt(n))
			: [];
	}

	get speedtestValuation() {
		return this.speedtestResults.length
			? this.speedtestResults.map((value: number, i: number) => {
					let percentage = value / [20, 10, 100, 40][i];
					percentage = (i > 1 ? 1 - percentage : percentage) * 100;
					return Math.max(Math.min(percentage, 100), 0);
				})
			: new Array(4).fill(null);
	}

	get hasBillingAddress() {
		return this.user.addresses
			? this.user.addresses.some(
					(a: APIaddress) =>
						a.accepts.includes('billing') || a.types.includes('billing'),
				)
			: false;
	}

	get hasWorkAddress() {
		return this.user.addresses
			? this.user.addresses.some(
					(a: APIaddress) =>
						a.accepts.includes('work') || a.types.includes('work'),
				)
			: false;
	}

	get hasValidTaxnumber() {
		return (
			!!this.user.taxNumber &&
			(!!this.user.vatIdMissingReason || this.user.euVatIdValid === 1)
		);
	}

	get hasValidBankaccount() {
		return (
			this.user.swiftOK !== 0 &&
			(this.user.iban || this.user.bankAccNum) &&
			this.user.ibanOK !== 0
		);
	}

	get hasAcceptedBillingAddress() {
		return this.user.addresses
			? this.user.addresses.some((a: APIaddress) =>
					a.accepts.includes('billing'),
				)
			: null;
	}

	get hasTaxInfo() {
		return this.user.taxModell !== 'unknown' || this.user.country !== 'DE';
	}

	get isTaxTransparencyCompliant() {
		return !this.user.billingAddressInEU || !!this.user.gender;
	}

	get hasPassport() {
		return (
			this.user.passportverify !== 'none' &&
			this.user.passportverify !== 'denied'
		);
	}

	get hasScriptedEvaluation() {
		return this.user.scriptedEvaluation === 'verified';
	}

	get hasNonScriptedEvaluation() {
		return this.user.nonScriptedEvaluation === 'verified';
	}

	get hasListeningEvaluation() {
		return this.user.listeningEvaluation === 'verified';
	}

	get hasSignedPrivacyDocs() {
		return (
			Number(this.user.signedPrivVersion) ===
			Number(window.appElement.statics.minPrivVersion)
		);
	}

	get isEmployed() {
		return this.user.employment === 'empl';
	}

	get obStep() {
		if (!this.isOnboarded) return '';
		if (this.user.onboardPreferred) return 'countdown';
		if (this.user.phoneMobile && this.hasBillingAddress && this.hasWorkAddress)
			return 'documents';
		return '';
	}

	getName(user = null) {
		const { name, pseudonym } = user || this.user;
		return `${name}${pseudonym ? ` (${pseudonym})` : ''}`;
	}
}

const User = new TalentUser();
export { User };
