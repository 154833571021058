export const RocketChatRouteService = {
	talent: {
		allowed: [
			{ route: 'home', condition: '', exceptions: [], rules: [''] },
			{
				route: 'contract',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{ route: 'teams', condition: '', exceptions: [], rules: [''] },
			{
				route: 'signdoc',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'profile',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'joboffers', condition: '', exceptions: [], rules: ['dodge'] },
			{
				route: 'projectoffer',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'projectofferinfo',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'trainings', condition: '', exceptions: [], rules: [] },
			{
				route: 'account',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'jobs',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'mailbox',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'downloads', condition: '', exceptions: [], rules: ['dodge'] },
			{ route: 'income', condition: '', exceptions: [], rules: ['dodge'] },
			{
				route: 'dialerwork',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'outboundwork',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'referrals', condition: '', exceptions: [], rules: ['dodge'] },
			{ route: 'score', condition: '', exceptions: [], rules: ['dodge'] },
			{
				route: 'calls',
				condition: '',
				exceptions: [],
				rules: [],
			},
			{
				route: 'speed',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'qualitychecks',
				condition: '',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'schedule',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'help', condition: '', exceptions: [], rules: ['background'] },
			{
				route: 'mystats',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
		],
		disallowed: [
			{
				route: 'join',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{ route: 'chat', condition: '', exceptions: [], rules: [''] },
			{
				route: 'onboarding',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'welcome',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{ route: 'webcamsession', condition: '', exceptions: [], rules: [''] },
			{ route: 'supportchat', condition: '', exceptions: [], rules: [''] },
			{ route: 'salestool', condition: '', exceptions: [], rules: [''] },
			{ route: 'adminsalestool', condition: '', exceptions: [], rules: [''] },
			{ route: 'identtsuccess', condition: '', exceptions: [], rules: [''] },
			{
				route: 'assistant',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{ route: 'tfa', condition: '', exceptions: [] },
		],
	},
	manage: {
		allowed: [
			{ route: 'home', condition: '', exceptions: [], rules: ['dodge'] },
			{
				route: 'cuaccount',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'qualityadd',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'quality',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'qualityview',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
			{
				route: 'qualitycheck',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'mapping', condition: '', exceptions: [], rules: [] },
			{
				route: 'teams',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'workforce',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
			{
				route: 'shifts',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'access', condition: '', exceptions: [], rules: ['dodge'] },
			{ route: 'users', condition: '', exceptions: [], rules: [] },
			{ route: 'members', condition: '', exceptions: [], rules: [] },
			{
				route: 'watchlists',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'monitor', condition: '', exceptions: [], rules: ['dodge'] },
			{
				route: 'dialerkpi',
				condition: '',
				exceptions: [],
				rules: [],
			},
			{
				route: 'mailbox',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'news',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'talent',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'stats_talents',
				condition: '',
				exceptions: [],
				rules: [],
			},
			{
				route: 'stats',
				condition: '',
				exceptions: [],
				rules: [],
			},
			{ route: 'report', condition: '', exceptions: [], rules: [] },
			{ route: 'findtalents', condition: '', exceptions: [], rules: ['dodge'] },
			{ route: 'balance', condition: '', exceptions: [], rules: ['dodge'] },
			{ route: 'voicefiles', condition: '', exceptions: [], rules: ['dodge'] },
			{
				route: 'terminationprices',
				condition: '',
				exceptions: [],
				rules: [],
			},
			{
				route: 'earnings',
				condition: '',
				exceptions: [],
				rules: [],
			},
			{
				route: 'finance',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
			{ route: 'projects', condition: '', exceptions: [], rules: ['dodge'] },
			{
				route: 'project',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{
				route: 'redirecting',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
			{
				route: 'training',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
			{
				route: 'trainings',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
			{ route: 'account', condition: '', exceptions: [], rules: ['dodge'] },
			{ route: 'addservice', condition: '', exceptions: [], rules: [] },
			{
				route: 'lists',
				condition: 'including-subviews',
				exceptions: [],
				rules: ['dodge'],
			},
			{ route: 'ibcalls', condition: '', exceptions: [], rules: [] },
			{
				route: 'talentmonitor',
				condition: '',
				exceptions: [],
				rules: ['dodge'],
			},
		],
		disallowed: [
			{ route: 'tfa', condition: '', exceptions: [], rules: [] },
			{ route: 'custom-db-queries', condition: '', exceptions: [], rules: [] },
		],
	},
	onboarding: {
		allowed: [],
		disallowed: [
			{ route: 'home', condition: '', exceptions: [], rules: [] },
			{
				route: 'talent',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
			{ route: 'webcamsessions', condition: '', exceptions: [], rules: [''] },
			{ route: 'verification', condition: '', exceptions: [], rules: [''] },
			{
				route: 'newsfeed',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'worthknowing',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'faq',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'schedule',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'supporttickets',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
		],
	},
	backoffice: {
		allowed: [],
		disallowed: [
			{ route: 'home', condition: '', exceptions: [], rules: [] },
			{
				route: 'talent',
				condition: 'including-subviews',
				exceptions: [],
				rules: [],
			},
			{ route: 'webcamsessions', condition: '', exceptions: [], rules: [''] },
			{ route: 'verification', condition: '', exceptions: [], rules: [''] },
			{
				route: 'newsfeed',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'worthknowing',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'faq',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'schedule',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
			{
				route: 'supporttickets',
				condition: 'including-subviews',
				exceptions: [],
				rules: [''],
			},
		],
	},
};
