import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import styles from './style.scss?inline';

@customElement('yd-content-list-item')
export default class YdContentListItem extends LitElement {
	static styles = [styles];

	render() {
		super.render();
		return html`
			<slot></slot>
		`;
	}
}
