import type * as prismic from '@prismicio/client';
import { prismicRichText } from '@utils/index';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import styles from './style.scss?inline';

const iconmap: { [key: string]: string } = {
	document: 'file-document-outline',
	gif: 'file-gif-box-outline',
	image: 'image-outline',
	video: 'video-outline',
	webpage: 'web',
};

@customElement('quick-tip')
export default class QuickTip extends LitElement {
	@property({ type: Object }) document: prismic.PrismicDocument['data'] = {};

	@property({ type: Boolean }) isOpen = false;

	static styles: CSSStyleSheet[] = [styles];

	render() {
		return html`
			${when(
				this.document,
				() => html`
					<yd-infobox
						icon="ymmd-light-bulb"
						ctatext=${window.T.jobapplication.tips}
						hasBorder
						ctaPosition
						.isContentVisible=${this.isOpen}
					>
						<div slot="content">
							${when(
								this.document.accordion.length > 0,
								() => html`
									${this.document.accordion.map(
										(item: {
											title: string;
											body: { text: unknown }[];
										}) => html`
											<sl-details class="toggle">
												<h4 slot="summary">${item.title}</h4>
												${prismicRichText(item.body as prismic.RichTextField)}
											</sl-details>
										`,
									)}
								`,
							)}
							${when(
								this.document.linklist_media.length > 0,
								() => html`
									<hr />
									<h4 class="media">
										<span>
											<iconify-icon
												class="iconify"
												icon="mdi-play-box-outline"
												width="20"
											></iconify-icon>
										</span>
										${window.T.term.media}
									</h4>
									${this.document.linklist_media.map(
										(media: {
											icon: string;
											link: { url: string; target: string };
											link_text: string;
										}) => html`
											<p class="media">
												<span>
													<iconify-icon
														class="iconify"
														icon="mdi-${iconmap[media.icon]}"
														width="20"
													></iconify-icon>
												</span>
												<a
													href="${media.link.url}"
													target="${media.link.target || '_blank'}"
												>
													${media.link_text || window.T.term.unknown}
												</a>
											</p>
										`,
									)}
								`,
							)}
						</div>
					</yd-infobox>
				`,
			)}
		`;
	}
}
