import { User } from '@app/User.ts';
import { globals } from '@globals';
import { deepNestedObjectToQueryString } from '@utils';
import { apiBaseURL } from '../API.ts';
import { Storage } from './Storage.js';

export default class ModelClass {
	constructor() {
		this.date = 'YYYY-MM-DD';
		this.time = `${this.date} HH:mm:ss`;
		this.abortControllers = new Map();

		this.storageWhitelist = new Map([['statics', () => true]]);

		this.stored = new Proxy(this, {
			get: (_, callname) => this.retrieveData(callname),
		});
		this.data = new Proxy(this.stored, {
			get:
				(_, callname) =>
				(params = {}, getFile = false) =>
					this.stored[callname](params, getFile, true),
		});

		this.Storage = new Storage(globals.appVersion.replaceAll('.', '_'));
		this.Storage.local = new Storage('', 'local');

		this.errorHandlingViews = ['callassistance', 'submitgraph', 'progressbar'];
		this.unhandledApiErrors = [
			'captchaRequired',
			'captchaWrong',
			'tfaloginrequired',
			'managed',
			'sound_active',
		];
	}

	abortRunningCalls(apiCalls) {
		this.abortControllers.forEach((controller, path) => {
			if (
				!apiCalls ||
				(apiCalls && Array.isArray(apiCalls) && apiCalls.includes(path))
			) {
				controller.abort();
				this.abortControllers.delete(path);
			}
		});
	}

	retrieveData(callname) {
		return async (params, getFile = false, live = false) => {
			if (!live) {
				if (this.Storage.has(callname)) return this.Storage.get(callname);
				if (this.Storage.local.has(callname)) {
					return this.Storage.local.get(callname);
				}
			}
			const result = await this.fetch(
				callname,
				params,
				getFile ? 'file' : 'json',
			);

			if (!result) return {};
			if (getFile) return result;
			const { success, time, auth, unreadMessages, user, ...rest } = result;

			if (!success) return result;

			if (
				user &&
				['login', 'appinit', 'account', 'resetpassword', 'tfa'].includes(
					callname,
				)
			) {
				await User.userObjectReceived(user);
			} else if (
				Object.keys(rest).length &&
				this.storageWhitelist.has(callname) &&
				this.storageWhitelist.get(callname)(params)
			) {
				this.Storage.set(callname, rest);
			}
			return result;
		};
	}

	async fetch(path = '', parametersObject = {}, expected = 'json') {
		const abortController = new AbortController();
		this.abortControllers.set(path, abortController);
		const { signal } = abortController;
		const apiAction = `${apiBaseURL}${path}`;
		const isFormData = parametersObject instanceof FormData;
		const headers = isFormData
			? {}
			: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				};
		const body = isFormData
			? parametersObject
			: deepNestedObjectToQueryString({
					...parametersObject,
					appVersion: globals.appVersion,
				});

		let response;
		if (window.appElement) {
			window.appElement.isLoading = this.abortControllers.size > 0;
		}
		try {
			response = await fetch(apiAction, {
				signal,
				headers,
				body,
				credentials: 'include',
				method: 'POST',
			});
			this.abortControllers.delete(path);
			if (window.appElement) {
				window.appElement.isLoading = this.abortControllers.size > 0;
			}
			if (!response.ok) {
				if (window.unloaded || response.status === 0) {
					return null;
				}
				if (response.status === 404) {
					// eslint-disable-next-line no-alert
					alert('Not found. [404]');
				} else if (response.status === 500) {
					// eslint-disable-next-line no-alert
					alert('Internal Server Error. [500]');
				} else {
					// eslint-disable-next-line no-alert
					alert(`Uncaught Error.\n${response.statusText}`);
				}
				window.Log.error(new Error(response.statusText));
			}
			if (expected === 'file') {
				// prevent file downloads with error content
				if (
					response.headers.get('Content-Type').startsWith('application/json')
				) {
					response = await response.json();
				} else {
					response = await response.blob();
				}
			} else {
				response = await response.json();
				response.sent = Object.assign(
					parametersObject instanceof FormData
						? Object.fromEntries(parametersObject)
						: parametersObject,
					{ path },
				);
			}
		} catch (err) {
			if (err.name !== 'AbortError' && err.name !== 'TypeError') {
				window.Log.error(err);
			}
		}

		if (!response || (!Object.keys(response).length && expected === 'json')) {
			return null;
		}

		return response.error
			? this.onApiError(response.error, path, response)
			: response;
	}

	resendAfterForceDialog(error, path, response) {
		window.Dialog.showDialog({
			html: window.T.alert.error[error],
			type: 'danger',
			titleText: window.T.term.error,
		}).then((value) => {
			if (!value) {
				window.appElement.requestUpdate();
				return null;
			}
			const { sent } = response;
			sent.force = 1;
			return this.fetch(path, sent);
		});
	}

	showError(path, error) {
		const errorDictionary =
			window.L10n.translations[window.L10n.language].alert.error;
		let errorMsg =
			errorDictionary[error] && typeof errorDictionary[error] === 'string'
				? errorDictionary[error]
				: '';

		errorMsg = errorDictionary[path]
			? errorDictionary[path][error] || errorMsg
			: errorMsg;
		window.Dialog.error(
			errorMsg || `${window.T.alert.error.unknownerror} ${error}`,
		);
		if (!errorMsg) {
			window.Log.error(
				new Error(`API returned error "${error}" calling ${path}`),
			);
		}
	}

	onApiError(error, path, response) {
		if (
			this.unhandledApiErrors.includes(error) ||
			this.errorHandlingViews.includes(path)
		) {
			return response;
		}

		// error-key-specific errorHandling, see e.g. this.onAPIauthError()
		if (
			this[`onAPI${error}Error`] &&
			typeof this[`onAPI${error}Error`] === 'function'
		) {
			this[`onAPI${error}Error`](error, path, response);
			// api-call-specific errorHandling, see e.g. this.tfaError()
		} else if (
			this[`${path}Error`] &&
			typeof this[`${path}Error`] === 'function'
		) {
			this[`${path}Error`](error, path, response);
		} else {
			this.showError(path, error);
			return null;
		}
		return response;
	}

	onAPItfarequiredError() {
		window.Router.navigate('/tfa');
	}

	onAPIauthError() {
		if (User.hasSession) window.Dialog.error(window.T.alert.error.auth);
		User.hasSession = false;
	}

	onAPIquotareachedError() {
		this.abortRunningCalls();
		window.Dialog.error(window.T.alert.error.quota_reached);
		window.Router.navigate('/', {
			callHandler: false,
			callHooks: false,
		});
	}

	onAPInotallowedError(error, path) {
		window.appElement.route.template?.destroy?.();
		this.abortRunningCalls();
		this.showError(path, error);
	}

	tfaresetError(error, path, response) {
		// explicitly nothing but a return needs to be done, error gets handled in caller func
		return response;
	}

	sendtfaresetcodeError(error, path, response) {
		// explicitly nothing but a return needs to be done, error gets handled in caller func
		return response;
	}

	mobilephoneverifyError(error, path, response) {
		// explicitly nothing but a return needs to be done, error gets handled in caller func
		return response;
	}

	loginError(error, path) {
		// the same wich captchaRequired & captchaWrong is needed for 'forgotpassword'!
		const errors = ['passwordtooold', 'captchaRequired', 'captchaWrong'];
		return errors.includes(error) ? null : this.showError(path, error);
	}

	async statics(refresh = false) {
		if (refresh) {
			this.Storage.remove('statics');
		}
		const statics = await this.stored.statics({
			lang: window.L10n.language,
			country: User.user.country || '',
		});
		const ustCountries = [
			'AT',
			'BE',
			'BG',
			'CY',
			'CZ',
			'DE',
			'DK',
			'EE',
			'GR',
			'ES',
			'FI',
			'FR',
			'GB',
			'HR',
			'HU',
			'IE',
			'IT',
			'LT',
			'LU',
			'LV',
			'MT',
			'NL',
			'PL',
			'PT',
			'RO',
			'SE',
			'SI',
			'SK',
			'CH', // CH?
		];
		const euCountries = ustCountries.filter((c) => c !== 'GB' || c !== 'CH');
		const euVatIdRequired = euCountries.filter((c) => c !== 'DE');
		const { countries } = statics;
		let { countrycodes } = statics;
		const countrycodeKeys = Object.keys(countrycodes);
		countrycodeKeys.sort((a, b) =>
			countries[a] ? countries[a].localeCompare(countries[b]) : -1,
		);
		countrycodes = Object.assign(
			{},
			...countrycodeKeys.map((c) => ({ [c]: statics.countrycodes[c] })),
		);

		const highlyDemandedLanguages = ['de', 'en', 'nl', 'fr', 'it', 'es'];

		return {
			...statics,
			ustCountries,
			euCountries,
			countrycodes,
			euVatIdRequired,
			lang: window.L10n.language,
			highlyDemandedLanguages,
		};
	}

	cleanup() {
		this.Storage.removeAll();
		this.Storage.local.removeAll([
			'caller-graph',
			'fifo-graph',
			'news_todos.archivedTodos',
			'news_todos.known_todos',
			'readNews',
			'archivedNews',
		]);
	}
}
