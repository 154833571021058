export const globals = {
	gui: import.meta.env.VITE_APP,
	appVersion: import.meta.env.VITE_APPVERSION,
	sentryOn: import.meta.env.VITE_SENTRY_ON === '1',
	isProduction: import.meta.env.VITE_IS_PRODUCTION === '1',
	iconifyUrl: import.meta.env.VITE_ICONIFY_URL,
	yoummdaySocket: import.meta.env.VITE_YOUMMDAY_SOCKET,
	hasYoummdaySocket: ['manage', 'onboarding', 'talent', 'backoffice'].includes(
		import.meta.env.VITE_APP,
	),
	rocketChatApi: import.meta.env.VITE_ROCKETCHAT_API,
	rocketChatSocket: import.meta.env.VITE_ROCKETCHAT_SOCKET,
	phoneSocket: import.meta.env.VITE_PHONE_SOCKET,
	languageEvaluationApi: import.meta.env.VITE_LANGUAGE_EVALUATION_API,
	cfTurnstileSitekey: import.meta.env.VITE_CF_TURNSTILE_SITEKEY,
};
