import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { LitElement, html } from 'lit';
import type { PropertyValues } from 'lit';
import { User } from '@app/User';
import style from './style.scss?inline';

// e.g. ['DE', ['49'], 'Deutschland']
type Nation = [string, [string, string?, string?], string];

@customElement('phone-number')
export default class PhoneNumber extends LitElement {
	@property({ type: String }) renderMode: 'input' | 'text' = 'input';

	@property({ type: String }) name: string = '';

	@property({ type: String }) number: string = '';

	@property({ type: String }) placeholder: string = '';

	@property({ type: Boolean }) disabled: boolean = false;

	@property({ type: Boolean }) required: boolean = false;

	@state() countryPrefix?: string;

	@query('input') input!: HTMLInputElement;

	@query('.prefix-select') select!: HTMLDivElement;

	numberWithoutPrefix: string = '';

	nations?: Array<Nation> | null = null;

	countrycode: string | undefined = '';

	exposedInput: HTMLInputElement | null = null;

	static styles = [style];

	@state() private typedChars: string = '';
	private timer: number | undefined;

	setCountryCode() {
		const nation = this.nations?.find((n) =>
			n[1].find((prefix) => prefix?.toString() === this.countryPrefix),
		);
		this.countrycode = nation?.[0]?.toLowerCase();
	}

	exposeInput() {
		let exposedInput = this.querySelector(
			':scope > input[type="hidden"]',
		) as HTMLInputElement;
		if (!exposedInput) {
			exposedInput = document.createElement('input') as HTMLInputElement;
			exposedInput.type = 'hidden';
			exposedInput.name = this.name;
			exposedInput.value = this.number;
			this.appendChild(exposedInput);
		} else {
			exposedInput.value = this.number;
		}
		if (!this.exposedInput) this.exposedInput = exposedInput;
	}

	init() {
		const { countries = {}, countrycodes = {} } =
			window.appElement?.statics || {};
		const l10nLang = window.L10n?.language || 'en';
		const userCountry =
			User.company?.country ||
			User.user?.country ||
			User.user?.lang ||
			(l10nLang as string);

		this.nations = Object.entries(countrycodes)
			.filter((cc) => countries[cc[0]])
			.map((cc) => [...cc, countries[cc[0]]] as Nation)
			.sort((a, b) => {
				if (a[2] < b[2]) return -1;
				if (a[2] > b[2]) return 1;
				return 0;
			});

		const sortedNationsByPrefixLength = [...this.nations].sort(
			(a: Nation, b: Nation) =>
				b[1][0].toString().length - a[1][0].toString().length,
		);

		const userNation: Nation | undefined =
			sortedNationsByPrefixLength.find((code: Nation) => {
				const hasPrefix = code[1].find((c) =>
					this.number.startsWith(c as string),
				);
				return !!hasPrefix;
			}) || this.nations.find((cc) => cc[0] === userCountry.toUpperCase());

		this.countryPrefix = userNation?.[1]?.[0];
		this.numberWithoutPrefix =
			this.number && this.countryPrefix
				? this.number.slice(this.countryPrefix.length)
				: '';

		this.setCountryCode();
		if (!this.disabled && this.renderMode === 'input') this.exposeInput();
	}

	connectedCallback(): void {
		super.connectedCallback();
		this.init();
	}

	protected updated(_changedProperties: PropertyValues<this>): void {
		if (_changedProperties.has('disabled')) {
			if (!this.disabled) this.exposeInput();
		}
	}

	togglePrefixSelection() {
		if (!this.disabled && this.select) {
			this.select.classList.toggle('active');
			if (this.select.classList.contains('active')) {
				this.select.focus();
				this.select
					.querySelector('.selected')
					?.scrollIntoView({ block: 'nearest' });
			}
		}
	}

	updateNumber(prefix: string = '', number: string = '') {
		if (prefix) {
			this.countryPrefix = prefix;
			this.setCountryCode();
		}
		this.numberWithoutPrefix = number;
		this.number = number;
		if (prefix && number) {
			this.number = `${prefix}${number}`;
		}
		if (this.exposedInput) this.exposedInput.value = this.number;
		const event = new CustomEvent('change', {
			detail: {
				name: this.name,
				value: this.number,
			},
			bubbles: true,
		});
		this.dispatchEvent(event);
	}

	onNumberChange(e: Event) {
		e.preventDefault();
		const input = e.currentTarget as HTMLInputElement;
		if (input && (input.validity.valid || input.value === '')) {
			this.updateNumber(this.countryPrefix, input.value);
		}
	}

	onPrefixChange(e: Event) {
		e.preventDefault();
		const target = e.currentTarget as HTMLDivElement;
		const { prefix } = target.dataset;
		this.select.blur();
		if (prefix && prefix !== this.countryPrefix) {
			this.updateNumber(prefix, this.numberWithoutPrefix);
		}
	}

	onKeyPress(e: KeyboardEvent) {
		if (this.timer) {
			clearTimeout(this.timer);
		}

		this.typedChars += e.key.toLowerCase();

		let nationName = this.nations?.find((nation) =>
			nation[2].toLowerCase().startsWith(this.typedChars),
		);

		if (!nationName) {
			nationName = this.nations?.find((nation) =>
				nation[1].some((prefix) => prefix?.startsWith(this.typedChars)),
			);
		}

		if (nationName) {
			const nationElement = this.select.querySelector(
				`[data-prefix="${nationName[1][0]}"]`,
			);
			if (nationElement) {
				nationElement.scrollIntoView({ block: 'center' });
			}
		}

		this.timer = window.setTimeout(() => {
			this.typedChars = '';
		}, 500);
	}

	render() {
		if (this.renderMode === 'text') {
			return html`
				+${this.countryPrefix} ${this.numberWithoutPrefix}
			`;
		}
		return html`
			<div class="input-group">
				<span
					class="input-group-text ${classMap({ disabled: this.disabled })}"
					@click=${this.togglePrefixSelection}
				>
					<iconify-icon
						class="flag"
						icon="flagpack:${this.countrycode}"
					></iconify-icon>
					<small>${this.countryPrefix ? `+ ${this.countryPrefix}` : ''}</small>
				</span>
				<input
					type="text"
					pattern="^(?=.*?[0-9 ]{5})s*[0-9 \\(\\)-\\/.+_]+$"
					maxlength="26"
					value=${this.numberWithoutPrefix}
					placeholder=${this.placeholder}
					?disabled=${this.disabled}
					?required=${this.required}
					@change=${this.onNumberChange}
				/>
			</div>
			<div
				class="prefix-select hidden"
				tabindex="-1"
				@blur=${() => this.togglePrefixSelection()}
				@keypress=${this.onKeyPress}
			>
				${this.nations?.map(
					(nation) => html`
						${nation[1].map(
							(prefix) => html`
								<div
									data-prefix=${prefix}
									class=${classMap({ selected: prefix === this.countryPrefix })}
									@click=${this.onPrefixChange}
								>
									<iconify-icon
										class="flag"
										icon="flagpack:${nation[0].toLowerCase()}"
									></iconify-icon>
									<span>${nation[2]} (+${prefix})</span>
								</div>
							`,
						)}
					`,
				)}
			</div>
		`;
	}
}
