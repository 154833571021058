import { globals } from '@globals';
import { Storage } from './Storage.js';

export const Log = {
	storage: new Storage('Log'),
	get debug() {
		return !globals.isProduction || this.storage.get('debug') === '1';
	},

	set debug(bool) {
		this.storage.set('debug', +bool);
	},
	log(...args) {
		if (!globals.isProduction || this.debug) {
			// eslint-disable-next-line no-console
			console.log(...args);
		}
	},
	error(err) {
		if (globals.sentryOn && typeof Sentry !== 'undefined') {
			Sentry.captureException(err);
		}
		// eslint-disable-next-line no-console
		console.error(err);
	},
};
