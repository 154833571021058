import { html, LitElement } from 'lit';
import type { CSSResultGroup } from 'lit';
import { customElement, property, state, queryAll } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import type { Ref } from 'lit/directives/ref.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { formatAsDuration } from '@utils';
import styles from './style.scss?inline';
import { openNoteformDialog } from '../../app/manage/modal/noteform.js';

type TranscriptionPart = [
	speaker: 0 | 1,
	start: number,
	stop: number,
	phrase: string,
	highlightedWordPosition?: number[],
];

interface IVoicefile {
	call?: object;
	callid: string;
	direction: 'inbound' | 'dialer' | 'outbound';
	ext: string;
	id: string;
	len: number;
	samplingrate: number;
	size: number;
	time: string;
	type: 'agent' | 'contract' | 'full' | 'mailbox';
	url: string;
	userCuId: string;
	userCuName: string;
	userSpId: string;
	userSpName: string;
	waveform: string;
	score: number;
	transcription: TranscriptionPart[];
	predictions?: [] | undefined;
}

interface Wave {
	svg: SVGElement;
	seeker: SVGLineElement;
	values: number[][];
}

const newSVGline = (): SVGLineElement =>
	document.createElementNS('http://www.w3.org/2000/svg', 'line');

const setSVGattributes = (
	el: SVGElement | SVGLineElement,
	attributes: object,
) => {
	Object.entries(attributes).forEach(([attr, val]) => {
		el.setAttribute(attr, val);
	});
};

@customElement('voice-file')
export default class VoiceFile extends LitElement {
	@property({ type: Object }) file: Partial<IVoicefile> = {};

	@property({ type: Boolean }) hasPredictions: boolean = false;

	@state()
	protected _wavedata = '';

	protected _wave: Partial<Wave> = {
		svg: <SVGElement>(
			document.createElementNS('http://www.w3.org/2000/svg', 'svg')
		),
		seeker: <SVGLineElement>newSVGline(),
		values: [],
	};

	protected _transcriptionContainer = <HTMLDivElement>{};

	protected _waveContainer = <HTMLDivElement | null>{};

	protected _audio = <HTMLAudioElement>{};

	protected _controls = {
		play: <HTMLSpanElement>{},
		pause: <HTMLSpanElement>{},
		duration: <HTMLSpanElement>{},
	};

	protected _isRendered = false;

	@queryAll('#transcript > span')
	transcriptionfragments?: NodeListOf<HTMLSpanElement>;

	transcriptRef: Ref<HTMLDivElement> = createRef();

	static styles = [styles] as CSSResultGroup;

	dateformat = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	} as const;

	timeformat = {
		hour: '2-digit',
		minute: '2-digit',
	} as const;

	updated() {
		if (this.transcriptRef.value) {
			this._transcriptionContainer = this.transcriptRef.value as HTMLDivElement;
		}
		if (!this._isRendered) {
			this.setup();
			this.renderPlayer();
			this._isRendered = true;
		}
	}

	toggleAudio() {
		if (this._audio.paused) {
			this._audio.play();
		} else {
			this._audio.pause();
		}
		this._controls.play.classList.toggle('active', this._audio.paused);
		this._controls.pause.classList.toggle('active', !this._audio.paused);
		this._waveContainer?.classList.toggle('active', !this._audio.paused);
	}

	setPlaybackSpeed(speed: number) {
		this._audio.playbackRate = speed;
	}

	onWaveformClick(e: MouseEvent) {
		if (this._waveContainer?.classList.contains('active')) {
			const { clientX } = e;
			const { left } = this._waveContainer.getBoundingClientRect();
			this._audio.currentTime =
				((clientX - left) / this.clientWidth) * this._audio.duration - 1;
		} else {
			this.toggleAudio();
		}
	}

	onAudioPlay() {
		const time = this._audio.currentTime;
		setSVGattributes(this._wave.seeker as SVGLineElement, {
			x1: time,
			x2: time,
		});
		this._controls.duration.innerText = formatAsDuration(time, 's');
		this.transcriptionfragments?.forEach((partEl, i) => {
			partEl.classList.toggle('played', Number(partEl.dataset.start) < time);
			partEl.classList.toggle(
				'current',
				Number(partEl.dataset.start) < time &&
					i + 1 === this.transcriptionfragments?.length,
			);
		});
	}

	onAudioEnd() {
		this._controls.play.classList.toggle('active', true);
		this._controls.pause.classList.toggle('active', false);
		this._controls.duration.innerText = formatAsDuration(0, 's');
	}

	setup() {
		const audiotoggles = this.renderRoot.querySelectorAll('.playpause > span');

		this._waveContainer = this.renderRoot.querySelector(
			'#waveform',
		) as HTMLDivElement | null;
		this._audio = this.renderRoot.querySelector('#audio') as HTMLAudioElement;
		this._controls.play = audiotoggles[0] as HTMLSpanElement;
		this._controls.pause = audiotoggles[1] as HTMLSpanElement;
		this._controls.duration = this.renderRoot.querySelector(
			'.duration span',
		) as HTMLSpanElement;

		if (this.file.waveform) {
			try {
				this._wavedata = atob(
					this.file.waveform.replace(/-/gu, '+').replace(/_/gu, '/'),
				);
			} catch (error) {
				window.Log?.error?.(error as Error);
				//
			}
		}

		if (this._wavedata) {
			const wavevalues = (str: string) => {
				const values = [];
				if (str.charCodeAt(0) === 1) {
					for (let i = 1; i < str.length; i += 1) {
						const v = str.charCodeAt(i);
						if (v < 101) {
							values.push([v, v]);
						} else if (v < 202) {
							i += 1;
							values.push([v - 101, str.charCodeAt(i)]);
						} else {
							for (let j = 200; j < v; j += 1) values.push([0, 0]);
						}
					}
				} else if (str.charCodeAt(0) === 2) {
					for (let i = 1; i < str.length; i += 1) {
						const v = str.charCodeAt(i);
						if (v < 101) {
							values.push([0, v]);
						} else if (v < 202) {
							values.push([v - 101, 0]);
						} else {
							i += 1;
							// eslint-disable-next-line no-bitwise
							const tmp = ((v - 202) << 8) + str.charCodeAt(i);
							const v2 = tmp % 101;
							values.push([(tmp - v2) / 101, v2]);
						}
					}
				}
				return values;
			};
			this._wave.values = wavevalues(this._wavedata);
		}
	}

	renderPlayer() {
		const height = 100;
		const width = this._wave.values?.length || Math.ceil(this.file.len || 0);
		let strokeWidth = 10 / width;
		let strokeWidthIndicator = strokeWidth;

		if (strokeWidth > 0.5) strokeWidth = 0.01;
		if (strokeWidth < 0.2) strokeWidth = 0.2;
		if (width > height * 3) {
			strokeWidth = 0.75;
			strokeWidthIndicator = 1;
		}

		this._wave.svg?.setAttributeNS(null, 'height', `${height}`);
		this._wave.svg?.setAttributeNS(null, 'width', '500'); // add 25px padding to right
		this._wave.svg?.setAttributeNS(null, 'viewBox', `0 0 ${width} ${height}`);
		this._wave.svg?.setAttributeNS(null, 'preserveAspectRatio', 'none');
		setSVGattributes(this._wave.seeker as SVGLineElement, {
			x1: 0,
			x2: 0,
			y1: 0,
			y2: height,
			'stroke-dasharray': 3,
			'stroke-width': strokeWidth,
		});
		const newLine = newSVGline();
		setSVGattributes(newLine, {
			x1: 0,
			x2: width,
			y1: height / 2,
			y2: height / 2,
		});
		this._wave.svg?.append(newLine);

		if (this.file.len && this.file.len > 10) {
			this._wave.values?.forEach((voices, i) => {
				const cm = Math.min(voices[0], voices[1]);
				const currentLine = newSVGline();
				setSVGattributes(currentLine, { x1: i, x2: i });

				if (voices[0] > voices[1]) {
					setSVGattributes(currentLine, {
						class: 'agent',
						y1: (100 - voices[0]) / 2,
						y2: (100 + voices[0]) / 2,
						'stroke-width': strokeWidth,
					});
					this._wave.svg?.append(currentLine);
				} else if (voices[0] < voices[1]) {
					setSVGattributes(currentLine, {
						class: 'caller',
						y1: (100 - voices[1]) / 2,
						y2: (100 + voices[1]) / 2,
						'stroke-width': strokeWidth,
					});
					this._wave.svg?.append(currentLine);
				}
				if (cm > 0) {
					const additionalLine = newSVGline();
					setSVGattributes(additionalLine, {
						x1: i,
						x2: i,
						y1: (100 - cm) / 2,
						y2: (100 + cm) / 2,
						'stroke-width': strokeWidthIndicator,
					});
					this._wave.svg?.append(additionalLine);
				}
			});
			if (this._wave.seeker) this._wave.svg?.append(this._wave.seeker);
		}
		if (this._wave.svg && this._waveContainer)
			this._waveContainer.prepend(this._wave.svg);
	}

	toggleTranscription() {
		if (this._transcriptionContainer.id) {
			this._transcriptionContainer.classList.toggle('active');
		}
	}

	header() {
		const {
			direction = '',
			score = 0,
			time = '',
			type = '',
			userCuName = '',
			userSpId = '',
			userSpName = '',
			predictions = null,
		} = this.file;
		const date = time ? new Date(time) : new Date();
		const getDirectionIcon = (dir = '') =>
			dir === 'inbound' ? 'phone-incoming' : 'phone-outgoing';
		const scoreClass = {
			'score-low': !!(score && score < 0.6),
			'score-medium': !!(score && score >= 0.6 && score < 1),
			'score-high': !!(score && score >= 1),
		};
		return html`
			<div class="header">
				<div class="col">
					<div class="prop">
						<iconify-icon
							icon="mdi:account"
							class="iconify"
							width="20"
						></iconify-icon>
						<a
							href="talent/${userSpId}"
							target="_blank"
						>
							${userSpName || window.T.term.unknown}
						</a>
					</div>
					<div class="prop">
						<iconify-icon
							icon="mdi:office-building"
							class="iconify"
							width="20"
						></iconify-icon>
						${userCuName || window.T.term.unknown}
					</div>
					<div class="prop">
						<iconify-icon
							icon="mdi:calendar-clock"
							class="iconify"
							width="20"
						></iconify-icon>
						${date.toLocaleDateString(
							window.L10n?.language || 'de',
							this.dateformat,
						)}
						|
						${date.toLocaleTimeString(
							window.L10n?.language || 'de',
							this.timeformat,
						)}
					</div>
				</div>
				<div class="col">
					<div class="prop ${predictions ? 'has-ai' : ''}">
						<iconify-icon
							icon="mdi:disc-player"
							class="iconify"
							width="20"
						></iconify-icon>
						${type ? window.T.api.voicefiles.type[type] : window.T.term.unknown}
						${when(
							predictions,
							() => html`
								<span>
									<yd-tooltip
										.tip=${window.T.hint.voicefile_hasPredictions}
										class="ai"
										left
										bottom
									>
										AI
									</yd-tooltip>
								</span>
							`,
						)}
					</div>
					<div class="prop">
						<iconify-icon
							icon="mdi:${getDirectionIcon(direction)}"
							class="iconify"
							width="20"
						></iconify-icon>
						${direction ? window.T.term[direction] : window.T.term.unknown}
					</div>
					${when(
						score,
						() => html`
							<div class="prop">
								<iconify-icon
									icon="mdi:voice"
									class="iconify"
									width="20"
								></iconify-icon>
								<yd-tooltip
									tip="${window.T.term.voicerecog_score}"
									class="${classMap(scoreClass)}"
								>
									${score}
								</yd-tooltip>
							</div>
						`,
					)}
				</div>
			</div>
		`;
	}

	controls() {
		const { len = 0, transcription = [], url = '', call = null } = this.file;
		return html`
			<div id="controls">
				<div
					class="playpause"
					@click=${this.toggleAudio}
				>
					<span class="hidden active">
						<iconify-icon
							class="iconify"
							icon="mdi:play-circle-outline"
							width="27"
						></iconify-icon>
					</span>
					<span class="hidden">
						<iconify-icon
							class="iconify"
							icon="mdi:pause-circle-outline"
							width="27"
						></iconify-icon>
					</span>
				</div>
				<div class="playbackspeed">
					<select
						@change=${(e: Event) =>
							this.setPlaybackSpeed(
								Number((e.currentTarget as HTMLSelectElement).value),
							)}
					>
						${[0.5, 1, 1.25, 1.5, 1.75, 2, 3].map(
							(speed) => html`
								<option
									value="${speed}"
									?selected=${speed === 1}
								>
									x ${speed}
								</option>
							`,
						)}
					</select>
					<iconify-icon
						class="iconify"
						icon="mdi:play-speed"
						width="20"
					></iconify-icon>
				</div>
				<div class="duration">
					<span>00:00</span>
					/ ${formatAsDuration(len, 's', false, false)}
				</div>
				<div class="extras">
					${when(
						transcription && transcription[0],
						() => html`
							<yd-tooltip
								tip=${window.T.term.speechtotext}
								@click=${this.toggleTranscription}
							>
								<iconify-icon
									class="iconify"
									icon="mdi:subtitles-outline"
									width="20"
								></iconify-icon>
							</yd-tooltip>
						`,
					)}
					${when(
						url,
						() => html`
							<a
								href=${url}
								target="_blank"
							>
								<yd-tooltip tip=${window.T.cta.file_download}>
									<iconify-icon
										class="iconify"
										icon="mdi:cloud-download"
										width="20"
									></iconify-icon>
								</yd-tooltip>
							</a>
						`,
					)}
					${when(
						call,
						() => html`
							<yd-tooltip
								tip=${window.T.label.call_notes}
								@click=${(e) => {
									e.preventDefault();
									openNoteformDialog(call);
								}}
							>
								<iconify-icon
									class="iconify"
									icon="mdi:clipboard-text-outline"
									width="20"
								></iconify-icon>
							</yd-tooltip>
						`,
					)}
				</div>
			</div>
		`;
	}

	render() {
		const { waveform = '', url = '' } = this.file;
		const { transcription = [] } = this.file;
		const speakerTranscriptions: TranscriptionPart[][] = [
			transcription.filter(([speaker]) => speaker === 1),
			transcription.filter(([speaker]) => speaker !== 1),
		];
		return html`
			<yd-card>
				${this.header()}
				<div id="content">
					${when(
						waveform,
						() => html`
							<div
								id="waveform"
								@click=${this.onWaveformClick}
							></div>
						`,
					)}
					${when(
						url,
						() => html`
							<audio
								id="audio"
								src="${url}"
								preload="none"
								controls
								@timeupdate=${this.onAudioPlay}
								@ended=${this.onAudioEnd}
							></audio>
						`,
					)}
					${when(
						transcription.length,
						() => html`
							<div
								id="transcript"
								class="hidden"
								${ref(this.transcriptRef)}
							>
								${speakerTranscriptions.map((speaker) =>
									speaker.map(
										([
											// eslint-disable-next-line @typescript-eslint/no-unused-vars
											_,
											start,
											stop,
											phrase,
											highlightedWordPosition = [],
										]) => html`
											<yd-tooltip tip=${formatAsDuration(start, 'ms')}>
												<span
													data-start=${(start / 1000).toFixed(2)}
													data-len=${(stop / 1000).toFixed(2)}
													class=${classMap({
														wordfound: highlightedWordPosition.length,
													})}
													@click=${() => {
														this._audio.currentTime = Number(
															this.dataset.start,
														);
													}}
												>
													${when(
														highlightedWordPosition,
														() => html`
															${phrase.split(' ').map((word, i: number) =>
																highlightedWordPosition.includes(i)
																	? html`
																			<b class="text-primary">${word}</b>
																		`
																	: word,
															)}
														`,
														() => phrase,
													)}
												</span>
											</yd-tooltip>
										`,
									),
								)}
							</div>
						`,
					)}
					${this.controls()}
				</div>
			</yd-card>
		`;
	}
}
