import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('recorder-countdown')
export default class RecorderCountdown extends LitElement {
	@property({ type: Number }) time = 5;

	@property({ type: Number }) intervalId: number | null = null;

	static styles = css`
		div {
			font-size: 2em;
			font-weight: bold;
		}
	`;

	connectedCallback() {
		super.connectedCallback();
		this.startTimer();
	}

	disconnectedCallback() {
		super.disconnectedCallback();

		if (this.time !== 0) {
			this.clearTimer();
		}
	}

	startTimer() {
		this.intervalId = window.setInterval(() => {
			if (this.time > 0) {
				this.time -= 1;
			} else {
				this.clearTimer();
			}
		}, 1000);
	}

	clearTimer() {
		const options = {
			bubbles: true,
			composed: true,
		};

		this.dispatchEvent(new CustomEvent('recorder-countdown-state', options));

		if (this.intervalId !== null) {
			clearInterval(this.intervalId);
			this.intervalId = null;
		}
	}

	render() {
		return html`
			<p>
				${window.T.hint.start_in}:
				<b>${this.time}</b>
				${window.T.unit.seconds_short}
			</p>
		`;
	}
}
