import { Model } from '@app/Model.js';
import { html, LitElement } from 'lit';
import { queryAll } from 'lit/decorators/query-all.js';
import type { PropertyValues, CSSResultGroup, TemplateResult } from 'lit';
import style from './style.scss?inline';
import { Log } from '../../app/Log.js';
import {
	onLinkClick,
	internalLinkSelector,
} from '@utils/evtHandler/internalLinkClick';
export default class PageBase extends LitElement {
	params: YdMatch['params'] = {};

	query: YdMatch['query'] = {};

	apicalls: object = {};

	_rawData: object = {};

	intervals: Set<ReturnType<typeof setTimeout>> = new Set();

	confirmNavigation: boolean = false;

	d: object = {};

	hasDataMissing: boolean = false;

	@queryAll(internalLinkSelector)
	nativeLinks!: HTMLCollection;

	static styles = [style] as CSSResultGroup;

	set rawData(obj) {
		// some data is undefined; api returned error;
		if (Object.values(obj).some((d) => !d)) return;
		Object.assign(this._rawData, obj);
		this.processData(Object.keys(obj));
	}

	get rawData() {
		return this._rawData;
	}

	processData(keys: string[] = []) {
		const processKeys = keys.length ? keys : Object.keys(this.rawData);
		processKeys.forEach((k) => {
			const processFunction: keyof PageBase =
				`process${k.charAt(0).toUpperCase()}${k.substring(1)}` as keyof PageBase;
			Object.assign(this.d, {
				[k]: this[processFunction] ? this[processFunction]() : this.rawData[k],
			});
		});
	}

	async connectedCallback() {
		const callsToMake = Object.keys(this.apicalls);
		const results = await Promise.all(
			callsToMake.map(async (call) => ({
				[call]: await Model.data[call](this.apicalls[call]),
			})),
		).catch((err) => {
			Log.error(err);
		});

		// were there apicalls and did they all fail?
		if (
			callsToMake.length &&
			(results?.every((r: object) => !Object.values(r)[0].success) ||
				results?.some(
					(r: object) => Object.values(r)[0].error === 'tfarequired',
				))
		) {
			super.connectedCallback();
			return;
		}

		Object.assign(this.rawData, ...results);
		this.processData();
		if (typeof this.d === 'object' && Object.keys(this.d).length) {
			Log.log(this.localName, this.d);
			if (callsToMake.length) {
				this.hasDataMissing = callsToMake.some((call) => {
					if (!this.d[call]) {
						Log.error(`this.d.${call} is undefined`);
						return true;
					}
					return false;
				});
				if (this.hasDataMissing) {
					window.Dialog?.error('Something went wrong. Try again later.');
				}
			}
		}
		super.connectedCallback();
	}

	shouldUpdate() {
		return !this.hasDataMissing;
	}

	updated(_changedProperties: PropertyValues<this>) {
		super.updated(_changedProperties);

		[...this.nativeLinks].forEach((a) => {
			a.addEventListener('click', onLinkClick);
		});
	}

	disconnectedCallback() {
		for (const interval of this.intervals) {
			clearInterval(interval);
			this.intervals.delete(interval);
		}
		Model.abortRunningCalls(Object.keys(this.apicalls));
		super.disconnectedCallback();
	}

	// eslint-disable-next-line class-methods-use-this
	render(): TemplateResult {
		return html`
			<!-- This should never be rendered -->
		`;
	}
}
