import { LitElement, html } from 'lit';
import type { CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import styles from './style.scss?inline';

const getButtonType = (type: string) => {
	switch (type) {
		case 'record':
			return 'mdi-circle';
		case 'stop':
			return 'mdi-stop';
		case 'play':
			return 'mdi-play-circle';
		default:
			return 'mdi-delete';
	}
};

@customElement('recorder-button')
export default class RecorderButton extends LitElement {
	static styles = [styles] as CSSResultGroup;

	@property({ type: Boolean }) isDisabled = false;

	@property({ type: String }) type: string = '';

	@property({ type: String }) text?: string = '';

	render() {
		return html`
			<button
				?disabled=${this.isDisabled}
				class="recorder-button ${this.type}"
			>
				<iconify-icon
					icon=${getButtonType(this.type)}
					class="iconify"
					width="25"
				></iconify-icon>
				${this.text}
			</button>
		`;
	}
}
