import moment from 'moment-timezone';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { User } from '@app/User.ts';

export const formatAsDuration = (
	duration = 0,
	unit = 'seconds',
	trimSeconds = false,
	prependHoursIfZero = false,
) => {
	if (Number(duration) !== duration) return '';
	if (!duration) return '00:00';
	const milliSeconds = moment.duration(Math.abs(duration), unit).as('ms');
	const format = `${
		milliSeconds < 3600000 && prependHoursIfZero ? 'H:' : ''
	}mm${trimSeconds ? '' : ':ss'}`;
	return (
		(milliSeconds >= 3600000 ? `${Math.floor(milliSeconds / 3600000)}:` : '') +
		moment.utc(milliSeconds).format(format)
	);
};

window.formatAsDuration = formatAsDuration;

export const formatAsMoney = (amount = 0, currency = 'EUR') => {
	if (Number.isNaN(parseFloat(amount))) return '';
	const displayCurrency = User.hasDollar ? 'USD' : currency;
	const maxDecimals = 4;
	// eslint-disable-next-line no-undefined
	const defaultMinimumDecimals = new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency: displayCurrency,
	}).resolvedOptions().minimumFractionDigits;
	const [noun, decimals] = `${amount}`.split('.');
	const receivedDecimals = decimals ? decimals.substring(0, maxDecimals) : '';
	const formatted = parseFloat(
		[noun, receivedDecimals].join('.'),
	).toLocaleString(window.L10n.language, {
		currency: displayCurrency,
		style: 'currency',
		minimumFractionDigits: Math.max(
			receivedDecimals.length,
			defaultMinimumDecimals,
		),
		maximumFractionDigits: maxDecimals,
	});
	if (receivedDecimals.length > defaultMinimumDecimals) {
		const lastIndex = formatted.lastIndexOf(
			receivedDecimals.substring(defaultMinimumDecimals),
		);
		return `${formatted.substring(0, lastIndex)}<span class="decimals">
			${receivedDecimals.substring(defaultMinimumDecimals)}
		</span>${formatted.substring(
			lastIndex + receivedDecimals.substring(defaultMinimumDecimals).length,
		)}`;
	}
	return formatted;
};

export const formatAsMoneyHtml = (amount = 0, currency = 'EUR') => {
	const str = formatAsMoney(amount, currency);
	return unsafeHTML(str);
};

export const formatNumber = (number = 0) => {
	if (Number.isNaN(parseFloat(number))) return '';
	return new Intl.NumberFormat().format(number);
};

export const formatCentToEuro = (value) => (value / 100).toFixed(2);
