import { LitElement, html } from 'lit';
import type { CSSResultGroup } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@frontend/yd-components';
import styles from './style.scss?inline';
import './recorder-countdown';
import './recorder-button';
import { formatTime } from './utils/formatTime';

@customElement('yd-audio-recorder')
export default class YdAudioRecorder extends LitElement {
	static styles = [styles] as CSSResultGroup;

	@property({ type: Object }) mediaRecorder: MediaRecorder | null = null;

	@state() recordedBlob: Blob | null = null;

	@state() isCountdownRunning: boolean = false;

	@state() isRecording = false;

	@state() isMicrophoneDisabled: boolean = false;

	@state() progress: string = '0:00';

	private _intervalId: number | null = null;

	private _startTime: number | null = null;

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener(
			'recorder-countdown-state' as string,
			this.handleCountdownChanged as EventListener,
		);
	}

	disconnectedCallback() {
		this.removeEventListener(
			'recorder-countdown-state' as string,
			this.handleCountdownChanged as EventListener,
		);
		super.disconnectedCallback();
	}

	handleCountdownChanged() {
		this.isCountdownRunning = false;
		this.mediaRecorder?.start();
		this.isRecording = true;
		this.progress = '0:00';
		this.startTimer();
	}

	updated(changedProperties: Map<string, unknown>) {
		const options = {
			detail: { value: this.recordedBlob },
			bubbles: true,
			composed: true,
		};

		if (changedProperties.has('recordedBlob')) {
			this.dispatchEvent(
				new CustomEvent('yd-audio-recorder-blob-changed', options),
			);
		}
	}

	async startRecording() {
		const microphonePermission = await navigator.permissions.query({
			name: 'microphone' as PermissionName,
		});
		if (microphonePermission.state === 'denied') {
			this.isMicrophoneDisabled = true;
			return;
		}

		if (!navigator?.mediaDevices?.getUserMedia) {
			return;
		}

		const audioChunks: BlobPart[] = [];

		this.mediaRecorder?.addEventListener('dataavailable', (event) => {
			audioChunks.push(event.data);
		});

		this.mediaRecorder?.addEventListener('stop', () => {
			this.recordedBlob = new Blob(audioChunks, { type: 'audio/wav' });
			this.stopTimer();
		});

		this.isCountdownRunning = true;

		const options = {
			bubbles: true,
			composed: true,
		};

		this.dispatchEvent(
			new CustomEvent('yd-audio-recorder-countdown-started', options),
		);
	}

	stopRecording() {
		this.mediaRecorder?.stop();
		this.isRecording = false;
		this.stopTimer();
		const audioElement =
			this.shadowRoot?.querySelector<HTMLAudioElement>('#audioPlayer');

		setTimeout(() => {
			if (audioElement && this.recordedBlob) {
				audioElement.src = URL.createObjectURL(this.recordedBlob);
			}
		}, 100);
	}

	playRecording() {
		const audioElement =
			this.shadowRoot?.querySelector<HTMLAudioElement>('#audioPlayer');

		if (audioElement && this.recordedBlob) {
			audioElement.src = URL.createObjectURL(this.recordedBlob);
			audioElement.play();
		}
	}

	deleteRecording() {
		const audioElement =
			this.shadowRoot?.querySelector<HTMLAudioElement>('#audioPlayer');

		if (audioElement) {
			audioElement.pause();
			audioElement.src = '';
		}

		this.recordedBlob = null;
	}

	startTimer() {
		this._startTime = Date.now();
		this._intervalId = window.setInterval(() => {
			const elapsedTime = Date.now() - (this._startTime as number);
			this.progress = formatTime(elapsedTime);
		}, 1000);
	}

	stopTimer() {
		if (this._intervalId !== null) {
			clearInterval(this._intervalId);
			this._intervalId = null;
		}
	}

	render() {
		return html`
			<div class="audio-recorder-wrapper">
				${this.isMicrophoneDisabled
					? html`
							<sl-alert
								variant="warning"
								open
							>
								<iconify-icon
									icon="material-symbols:warning-outline-rounded"
									slot="icon"
								></iconify-icon>
								<p>${window.T.hint.call_browser_permissions}</p>
							</sl-alert>
						`
					: ''}
				<div class="${this.recordedBlob ? 'audio-player-wrapper' : ''}">
					<audio
						id="audioPlayer"
						class="audio-player"
						?controls=${!!this.recordedBlob}
					></audio>
					${this.recordedBlob
						? html`
								<recorder-button
									@click=${this.deleteRecording}
									?isdisabled=${this.isRecording}
									type="delete"
								></recorder-button>
							`
						: ''}
				</div>
				${this.isCountdownRunning
					? html`
							<recorder-countdown></recorder-countdown>
						`
					: ''}
				${this.isRecording
					? html`
							<p class="progress">
								<iconify-icon
									icon="mdi-microphone"
									class="iconify microphone"
									width="25"
								></iconify-icon>
								${this.progress}
							</p>
						`
					: ''}
				<div class="controller">
					${html`
						<recorder-button
							@click=${this.startRecording}
							?isdisabled=${this.isRecording || this.isCountdownRunning}
							type="record"
							text=${window.T.term.record}
						></recorder-button>
					`}
					${html`
						<recorder-button
							@click=${this.stopRecording}
							?isDisabled=${!this.isRecording}
							type="stop"
							text=${window.T.term.stop}
						></recorder-button>
					`}
				</div>
			</div>
		`;
	}
}
