import { LitElement, html } from 'lit';
import { User } from '@app/User';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { globals } from '@globals';
import { RocketChatService } from '@components/services/RocketChatService.js';
import { Model } from '@app/Model.js';

import style from './style.scss?inline';

@customElement('yd-chat')
export default class YdChat extends LitElement {
	@property({ type: Array }) rules: string[] = [];

	@query('yd-card .content') contentContainer!: LitElement;

	@query('header #chevron') chevronIcon!: SVGAElement;

	@state() realtimeMessages: Message[] = [];

	rooms: Room[] = [];

	token = '';

	storedRooms: Room[] = [];

	fetchedRooms: Room[] = [];

	interval: ReturnType<typeof setInterval> | null = null;

	static styles = [style];

	@state() selectedRoom: Room = { name: '', projectName: '', rid: '' };
	@state() selectedChannel: Channel = { rid: '', name: '' };

	constructor() {
		super();

		({ token: this.token, rooms: this.rooms } =
			RocketChatService.getRocketChatProperties());
	}

	async heartBeat() {
		await Model.data.heartbeat();
	}

	async checkRoomChanges() {
		this.storedRooms = RocketChatService.getRocketChatProperties('rooms');
		this.fetchedRooms = await RocketChatService.getRooms();
		if (
			this.fetchedRooms &&
			JSON.stringify(this.storedRooms) !== JSON.stringify(this.fetchedRooms)
		) {
			RocketChatService.setRocketChatProperties(this.fetchedRooms);
			this.rooms = this.fetchedRooms;
			this.selectedRoom =
				this.fetchedRooms.find((room) => room.id === this.selectedRoom.id) ||
				this.selectedRoom;
			this.requestUpdate();
		}
	}

	async refreshToken() {
		({ token: this.token } = await RocketChatService.getAuth());
		const rcStorage = Model.Storage.get('rocketchat');
		Model.Storage.set(
			'rocketchat',
			Object.assign(rcStorage, {
				token: this.token,
			}),
		);
		return this.token;
	}
	rcOpen() {
		const rcUrl = `${globals.rocketChatApi}/home?resumeToken=${this.token}`;
		window.open(rcUrl, '_blank').focus();
	}

	async refresh() {
		await this.heartBeat();
		await this.checkRoomChanges();
	}

	poll() {
		return setInterval(() => this.refresh(), 30000) as ReturnType<
			typeof setInterval
		>;
	}

	connectedCallback() {
		super.connectedCallback();
		this.checkRoomChanges();
		this.interval = this.interval || this.poll();
		[this.selectedRoom] = this.rooms;
		const rcStorage = Model.Storage.get('rocketchat');
		Model.Storage.set(
			'rocketchat',
			Object.assign(rcStorage, {
				selectedRoom: this.selectedRoom,
				api: globals.rocketChatApi,
			}),
		);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		if (this.interval) clearInterval(this.interval);
	}

	expand(): void {
		this.contentContainer.classList.toggle('expanded');
		this.chevronIcon.classList.toggle('rotate');
	}

	selectRoom(room: Room) {
		this.selectedRoom = room;
		const rcStorage = Model.Storage.get('rocketchat');
		delete rcStorage.selectedChannel;
		Model.Storage.set(
			'rocketchat',
			Object.assign(rcStorage, {
				selectedRoom: this.selectedRoom,
			}),
		);
	}

	selectChannel(channel: Channel) {
		this.selectedChannel = channel;
		const rcStorage = Model.Storage.get('rocketchat');
		Model.Storage.set(
			'rocketchat',
			Object.assign(rcStorage, { selectedChannel: this.selectedChannel }),
		);
	}

	openExternalChat(name = 'yoummday chat') {
		window.open('/chat.html', name, 'width=800,height=600');
	}

	hasUnreadMessages() {
		return this.realtimeMessages?.some((message) => message.unread !== 0);
	}

	render() {
		if (this.rules.includes('dodge')) {
			const container: HTMLElement = <HTMLElement>(
				window.appElement.route.template?.container
			);
			const spacer = document.createElement('div');
			spacer.classList.add('spacer');
			container?.appendChild(spacer);
			window.appElement.render();
		}
		return html`
			<div class="chat">
				<yd-card>
					<header class="flex headline">
						<div>
							<user-thumb
								size="42"
								.user=${User.user}
								.showXps=${false}
							></user-thumb>
							<span class="chat-title">${window.T.api.balance.chat}</span>
							<yd-tooltip tip=${window.T.label.notifications_mail_newmessages}>
								<span
									class=${classMap({
										flasher: this.hasUnreadMessages(),
									})}
								></span>
							</yd-tooltip>
						</div>
						<div>
							<track-link
								class="d-inline-block"
								blank
								@click=${() => {
									this.refreshToken();
									this.rcOpen();
								}}
							>
								<yd-tooltip tip=${window.T.term.open_new_window}>
									<span class="fluenticon"></span>
								</yd-tooltip>
							</track-link>
							<span
								class="expand"
								@click=${this.expand}
							>
								<iconify-icon
									id="chevron"
									icon="mdi:chevron-up"
									class="iconify"
									width="24"
								></iconify-icon>
							</span>
						</div>
					</header>

					<div class="content">
						<yd-carousel>
							${this.rooms.map(
								(room, index) => html`
									<yd-carousel-item
										@click=${() => this.selectRoom(room)}
										?active=${index === 0}
									>
										<user-thumb
											forCarousel
											?newsindicator=${this.checkUnread(room)}
											size="42"
											.user=${room.companyUser}
										></user-thumb>
									</yd-carousel-item>
								`,
							)}
						</yd-carousel>
						<yd-content-list scrollable>
							<yd-content-list-item
								@click=${() => {
									this.refreshToken();
									this.selectRoom(this.selectedRoom);
									this.openExternalChat(this.selectedRoom.name);
								}}
							>
								<div class="yd-content-list-item-wrapper">
									<span class="yd-content-list-item-title">
										${this.selectedRoom?.name}
									</span>
									<span
										class=${classMap({
											'new-indicator': this.checkUnread(this.selectedRoom),
										})}
										title="${window.T.term.new}"
									></span>
								</div>
							</yd-content-list-item>
							${this.selectedRoom?.channels?.map(
								(channel) => html`
									<yd-content-list-item
										@click=${() => {
											this.refreshToken();
											this.selectChannel(channel);
											this.openExternalChat(channel.name);
										}}
									>
										<div class="yd-content-list-item-wrapper">
											<span class="channel-label">
												<iconify-icon
													icon="mdi:hashtag"
													class="iconify channel-icon"
													width="18"
												></iconify-icon>
												<span class="channel-label">${channel.name}</span>
											</span>
											<span
												class=${classMap({
													'new-indicator': this.checkUnread(channel),
												})}
												title="${window.T.term.new}"
											></span>
										</div>
									</yd-content-list-item>
								`,
							)}
						</yd-content-list>
					</div>
				</yd-card>
			</div>
		`;
	}

	checkUnread(room: Room): boolean {
		return this.realtimeMessages?.some(
			(message: Message) =>
				message.unread !== 0 &&
				(message.rid === room.rid ||
					room.channels?.some(
						(channel: Channel) => message.rid === channel.rid,
					)),
		);
	}
}
