import { unsafeHTML } from 'lit/directives/unsafe-html.js';

export const Dialog = {
	showDialog(args) {
		return new Promise((resolve) => {
			window.appElement.dialog = {
				...args,
				resolve,
			};
		});
	},
	async queueDialogs(dialogs = []) {
		let result = true;
		for (const dialog of dialogs) {
			// eslint-disable-next-line no-await-in-loop
			const { isDismissed } = await this.showDialog(dialog);
			if (isDismissed) {
				result = false;
				break;
			}
			result = result && !isDismissed;
		}
		return result;
	},
	error(str) {
		this.showDialog({
			html: unsafeHTML(str),
			noDismissButton: true,
			type: 'danger',
			titleText: window.T.term.error,
		});
	},
	message(str, variant = 'success') {
		window.appElement.dispatchEvent(
			new CustomEvent('showMessage', {
				detail: { str, variant },
			}),
		);
	},
};
