import notificationIcon from '@img/notification.png';

export const notification = (title, body = '', lang = 'en') => {
	if (!title || !document.hidden) return null;
	const banner = new Notification(title, {
		body,
		lang,
		icon: notificationIcon,
	});
	banner.addEventListener(
		'notificationclick',
		() => {
			banner.close();
			window.focus();
		},
		false,
	);
	return banner;
};
