import * as prismic from '@prismicio/client';
import type { CSSResultGroup } from 'lit';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import styles from './style.scss?inline';

@customElement('information-card')
export default class InformationCard extends LitElement {
	@property({ type: Object }) document: prismic.PrismicDocument['data'] = {};

	static styles: CSSResultGroup = [styles];

	render() {
		const { items, title, image, description } = this.document;

		return html`
			${when(
				this.document,
				() => html`
					<div slot="content">
						<yd-card class="information-card">
							<div class="info">
								<div class="title">
									<iconify-icon
										icon="mdi-clipboard-check-multiple"
									></iconify-icon>
									${title[0].text}
								</div>
								<div class="description">${description}</div>
								<div>
									${when(
										items && items.length > 0,
										() => html`
											<ul>
												${items.map(
													(item: { icon: string; text: string }) => html`
														<li>
															<iconify-icon
																icon=${`mdi-${item.icon}`}
															></iconify-icon>
															${item.text}
														</li>
													`,
												)}
											</ul>
										`,
									)}
								</div>
							</div>
							<div class="img">
								<img
									src=${image.url}
									alt=${image.alt || 'Image'}
									srcset=${`
                    ${image.url}?w=320 320w,
                    ${image.url}?w=480 480w,
                    ${image.url}?w=800 800w
                  `}
									sizes="(max-width: 320px) 280px, 
                         (max-width: 480px) 440px, 
                         (max-width: 620px) 600px, 
                         800px"
									loading="lazy"
								/>
							</div>
						</yd-card>
					</div>
				`,
			)}
		`;
	}
}
