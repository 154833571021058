import { type RichTextField } from '@prismicio/client';
import * as richtext from '@prismicio/richtext';
import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

const regExNewLine = /\n/gu;

// An object mapping rich text block types to Markdown.
const markdownSerializer = richtext.wrapMapSerializer({
	heading1: ({ children }) => `<h1>${children.join('')}</h1>`,
	heading2: ({ children }) => `<h2>${children.join('')}</h2>`,
	heading3: ({ children }) => `<h3>${children.join('')}</h3>`,
	heading4: ({ children }) => `<h4>${children.join('')}</h4>`,
	heading5: ({ children }) => `<h5>${children.join('')}</h5>`,
	heading6: ({ children }) => `<h6>${children.join('')}</h6>`,
	paragraph: ({ children }) => `<p>${children.join('')}</p>`,
	preformatted: ({ text }) => `<code>${text}</code>`,
	strong: ({ children }) => `<strong>${children.join('')}</strong>`,
	em: ({ children }) => `<em>${children.join('')}</em>`,
	listItem: ({ children }) => `<li>${children.join('')}</li>`,
	oListItem: ({ children }) => `<li>${children.join('')}</li>`,
	list: ({ children }) => `<ul>${children.join('')}</ul>`,
	oList: ({ children }) => `<ol>${children.join('')}</ol>`,
	image: ({ node }) =>
		node.linkTo
			? `<a href="${node.linkTo.url}"><img alt="${node.alt}" src="${node.url}" /></a>`
			: `<img alt="${node.alt}" src="${node.url}" />`,
	embed: ({ node }) => `${node.oembed.html}\n\n`,
	hyperlink: ({ node, children }) =>
		`<a href="${node.data.url}" ${node.data?.target === '_blank' ? 'target="_blank"' : ''} rel="noopener noreferrer">${children.join('')}</a>`,
	label: ({ children }) => children.join(''),
	span: ({ text }) => text.replace(regExNewLine, '<br/>'),
});

export const prismicRichText = (prismicRichtext: RichTextField) => html`
	${unsafeHTML(
		richtext.serialize(prismicRichtext, markdownSerializer).join(''),
	)}
`;
