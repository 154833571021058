import { html, LitElement } from 'lit';
import {
	customElement,
	property,
	query,
	queryAssignedElements,
	state,
} from 'lit/decorators.js';
import YdCarouselItem from '../yd-carousel-item/yd-carousel-item';
import styles from './style.scss?inline';

@customElement('yd-carousel')
export default class YdCarousel extends LitElement {
	static styles = [styles];

	@property({ type: Number }) currentIndex = 0;

	@property({ type: Number }) steps = 5;

	@query('.carousel-container') carouselContainer!: HTMLDivElement;

	@queryAssignedElements({ selector: 'yd-carousel-item' })
	ydCarouselItems!: Array<YdCarouselItem>;

	@state() carouselItemsCount = 0;

	render() {
		return html`
			<div class="carousel-wrapper">
				<div
					@click=${this.handlePrev}
					class="slider-btn"
					?hidden=${this.isCarouselStart()}
				>
					<iconify-icon
						icon="mdi-chevron-left"
						class="iconify ms-0"
						width="34"
					></iconify-icon>
				</div>
				<div class="carousel-list">
					<div class="carousel-container">
						<slot @slotchange=${this.handleSlotChange}></slot>
					</div>
				</div>
				<div
					@click=${this.handleNext}
					?hidden=${this.isCarouselEnd()}
					class="slider-btn"
				>
					<iconify-icon
						icon="material-symbols:chevron-right-rounded"
						class="iconify ms-0"
						width="34"
					></iconify-icon>
				</div>
			</div>
		`;
	}

	handlePrev() {
		const isFirstStep = this.currentIndex < this.steps;
		this.currentIndex = isFirstStep ? 0 : this.currentIndex - this.steps;
		this.slideCarousel();
	}

	handleNext() {
		const isWithinBounds =
			this.currentIndex < this.carouselItemsCount &&
			this.currentIndex + this.steps < this.carouselItemsCount;

		this.currentIndex += this.steps;
		this.slideCarousel();

		if (!isWithinBounds) {
			this.currentIndex = Math.min(this.currentIndex, this.carouselItemsCount);
			this.slideCarousel();
		}
	}

	slideCarousel() {
		const wipeWidth = this.ydCarouselItems[0].getBoundingClientRect().width;
		const remainingSteps = this.carouselItemsCount - this.currentIndex;
		if (remainingSteps < this.steps) {
			this.carouselContainer.style.transform = `translateX(-${
				wipeWidth *
				(this.carouselItemsCount -
					remainingSteps -
					this.steps -
					1 +
					remainingSteps +
					1)
			}px)`;
			//this.currentIndex = this.carouselItemsCount;
		} else {
			this.carouselContainer.style.transform = `translateX(-${
				wipeWidth * (this.carouselItemsCount - remainingSteps)
			}px)`;
		}
	}

	toggleActiveState(clickedItem: YdCarouselItem, active: boolean) {
		clickedItem.setAttribute('active', !active);
		clickedItem.classList.toggle('active-item', active);
	}

	activateElement(e: CustomEvent) {
		const { active, item: clickedItem } = e.detail;

		const slot = this.shadowRoot?.querySelector('slot');
		const assignedNodes = slot?.assignedNodes({ flatten: true });

		assignedNodes?.forEach((node) => {
			if (
				node instanceof HTMLElement &&
				node.tagName.toLowerCase() === 'yd-carousel-item'
			) {
				node.classList.remove('active-item');
			}
		});

		this.ydCarouselItems.forEach((item) => {
			if (item === clickedItem) {
				this.toggleActiveState(clickedItem, active);
			} else {
				item.removeAttribute('active');
				item.classList.remove('active-item');
			}
		});
	}

	handleSlotChange() {
		this.carouselItemsCount = this.ydCarouselItems.length;

		this.ydCarouselItems.forEach((item) => {
			item.addEventListener('item-click', (e: CustomEvent) => {
				this.activateElement(e);
			});
		});
	}

	isCarouselStart() {
		return this.currentIndex === 0;
	}

	isCarouselEnd() {
		return (
			this.carouselItemsCount === 0 ||
			this.currentIndex >= this.carouselItemsCount - 1 ||
			this.steps >= this.carouselItemsCount
		);
	}
}
