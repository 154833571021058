import moment from 'moment-timezone';
import { html } from 'lit';
import { br } from '@utils';

const getDialog = (call) => html`
	<yd-table>
		<yd-table-tbody>
			${call.notes.fields.map(
				(note) => html`
					<yd-table-row>
						<yd-table-cell>
							${note.type === 'header'
								? html`
										<h4>${note.label}</h4>
									`
								: html`
										<b>${note.label}</b>
									`}
						</yd-table-cell>
						<yd-table-cell>
							${note.type === 'textarea' ? br(note.value) : ''}
							${note.type === 'checkbox' && parseInt(note.value) === 1
								? html`
										<iconify-icon
											icon="mdi-check"
											class="iconify"
										></iconify-icon>
									`
								: ''}
							${!['textarea', 'checkbox', 'priority', 'header'].includes(
								note.type,
							)
								? note.value
								: ''}
						</yd-table-cell>
					</yd-table-row>
				`,
			)}
		</yd-table-tbody>
	</yd-table>
`;

export const openNoteformDialog = (call, user) => {
	window.Dialog.showDialog?.({
		html: getDialog(call),
		noDismissButton: true,
		noConfirmButton: true,
		size: 'large',
		titleText: html`
			${user
				? html`
						<em>
							${user.name}
							<span class="pseudonym">${user.pseudonym}</span>
						</em>
					`
				: ''}
			<b>${moment(call.time).format('L | LT')}</b>
		`,
	});
};
